.award {
    text-align: center;
}

.award__top {
    margin-bottom: $grid-gutter;
    padding-bottom: $grid-gutter;
    position: relative;

    // rule
    &::after {
        background-color: $color-lola;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: column(4);
    }
}

.award__count {
    @include text-real-big;
    display: block;
}
