@for $i from 1 through $grid-columns {
    .offset--#{$i} {
        margin-left: column($i);
    }
}

@include breakpoint(small) {
    @for $i from 1 through $grid-columns {
        .offset--#{$i}-small {
            margin-left: column($i);
        }
    }
}

@include breakpoint(medium) {
    @for $i from 1 through $grid-columns {
        .offset--#{$i}-medium {
            margin-left: column($i);
        }
    }
}

@include breakpoint(large) {
    @for $i from 1 through $grid-columns {
        .offset--#{$i}-large {
            margin-left: column($i);
        }
    }
}
