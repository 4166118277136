.topic {
    @include text-button-small;
    background-color: $color-pink;
    border-radius: rem(12px);
    color: $color-peach;
    padding: $grid-gutter-xsmall $grid-gutter-small;
    transition: background-color $speed-fast;

    &:hover {
        background-color: $color-bazaar;
    }
}

.topic--peach {
    background-color: $color-peach;
    color: $color-bazaar;

    &:hover {
        background-color: $color-ebb;
    }
}
