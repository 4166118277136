@for $i from 1 through $css-columns {
    .css-columns--#{$i} {
        @include columns($i);
    }
}

.css-columns--none {
    @include no-columns;
}

@each $breakpoint in map-keys($breakpoints) {
    @include breakpoint($breakpoint) {
        @for $i from 1 through $css-columns {
            .css-columns--#{$i}-#{$breakpoint} {
                @include columns($i);

                ul, ol {
                    break-inside: avoid-column;
                }
            }
        }

        .css-columns--none-#{$breakpoint} {
            @include no-columns;
        }
    }
}
