.text-with-embed {
    code {
        display:none;
    }
    .embedded-code {
        &.hidden {
            display:none;
        }
    }
}
