// Default rem-baseline is 16px (100%)

html {
    @include rem-baseline(85%);

    @include breakpoint(small) {
        @include rem-baseline(90%);
    }

    @include breakpoint(medium) {
        @include rem-baseline(95%);
    }

    @include breakpoint(large) {
        @include rem-baseline(100%);
    }
}
