.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

@each $breakpoint in map-keys($breakpoints) {
    @include breakpoint($breakpoint) {
        .text-align-left-#{$breakpoint} {
            text-align: left;
        }

        .text-align-center-#{$breakpoint} {
            text-align: center;
        }

        .text-align-right-#{$breakpoint} {
            text-align: right;
        }
    }
}
