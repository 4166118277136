// sass-lint:disable no-important
// !important necessary here because we set filter styles inline

.filter {
    transition: filter $speed-medium;

    .link:hover &,
    .thumb:hover & {
        filter: none !important;
    }
}

.filter--no-color {
    filter: none !important;
}

.filter__image {
    visibility: hidden;
}
