.video {
    padding-top: 56.25%;  // default aspect ratio based on youtube embeds
    position: relative;
}

.video--pbs {
    padding-top: 65%; // aspect ratio based on pbs embeds
}

.video__iframe {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
}
