.rich-text {

    ul,
    ol {
        list-style: inherit;
        padding-left: 15px;
    }

    @include text-body;

    > * {
        margin-top: $grid-gutter;

        &:first-child {
            margin-top: 0;
        }
    }

    a {
        border-bottom: 1px solid $color-black;
        transition: color $speed-fast, border-color $speed-fast;

        &:hover {
            border-color: transparent;
            color: $color-dark-red;
        }
    }

    i,
    em {
        font-style: italic;
    }

    b,
    strong {
        font-weight: weight(bold);
    }

    h4 {
        @include h4;
        margin-top: $grid-gutter-large;

        &:first-child {
            margin-top: 0;
        }
    }

    h5 {
        @include h5;
        color: $color-bazaar;
        margin-top: $grid-gutter-medium;

        &:first-child {
            margin-top: 0;
        }
    }

    ol {
        counter-reset: rich-text-counter;
        margin-top: $grid-gutter-medium;
        padding-left: $grid-gutter-medium;

        li {
            margin-top: $grid-gutter;
            position: relative;

            &:first-child {
                margin-top: 0;
            }

            &::before {
                content: counter(rich-text-counter, decimal) '.';
                counter-increment: rich-text-counter;
                margin-right: $grid-gutter-small;
                position: absolute;
                right: 100%;
                text-align: right;
                width: $grid-gutter;
            }
        }
    }
}

.rich-text--x-leading {
    line-height: 1.813;
}

.rich-text--small {
    @include text-body-small;
}
