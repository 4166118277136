.svg__path,
.svg__line,
.svg__circle {
    transition: fill $speed-fast, stroke $speed-fast;
}

.svg--play {
    .svg__path {
        fill: $color-yellow;
        fill-opacity: 0;
        stroke: $color-yellow;
        transition: fill $speed-fast, stroke $speed-fast, fill-opacity $speed-fast;
    }
}

.svg--arrow-down,
.svg--close {
    .svg__path {
        fill: none;
        stroke-linecap: square;
    }
}
