.ch-audio__transition{
  transition: all ease-in-out 500ms;
}
.public-update__ch-audio{
  position: relative;
  flex: 1 0;

  .ch-audio{
    &__info{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: scale(0);
      overflow: hidden;
      background-color: rgba($color-black, 0.8);
      box-shadow: 0 5px 15px rgba(0,0,0,0.3);
      z-index: 10;
      &.show{
        animation: zoomIn 1s ease-in-out;
        transform: scale(1);
        padding: 0;
      }

      .icon--close {
        z-index: 1000;
      }
    }

    &__waveform{
      height: 100%;
      width: 100%;
      cursor: pointer;
      z-index: 0;
      @extend .ch-audio__transition;
    }

    &__player{
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;

      .loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        &.hidden {
          display: none;
        }
        .loader,
        .loader:before,
        .loader:after {
          background: #e62b12;
          -webkit-animation: load1 1s infinite ease-in-out;
          animation: load1 1s infinite ease-in-out;
          width: 1em;
          height: 4em;
        }
        .loader {
          color: #e62b12;
          text-indent: -9999em;
          margin: 88px auto;
          position: relative;
          font-size: 14px;
          -webkit-transform: translateZ(0);
          -ms-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s;
        }
        .loader:before,
        .loader:after {
          position: absolute;
          top: 0;
          content: '';
        }
        .loader:before {
          left: -1.5em;
          -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
        }
        .loader:after {
          left: 1.5em;
        }
      }
    }
  }

  .stop{
    .ch-audio__waveform,
    .player__skip-btn{
      opacity: 0.1;
    }
    .player__start-btn{
      bottom: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 150px;
      height: 150px;
      svg{
        width: 150px;
        height: 150px;
      }

      &.hidden {
        display: none;
      }
    }
  }

  .info{
    &__icon{
      position: fixed;
      top: 30px;
      right: 30px;
      border-radius: 50%;
      background-color: $color-peach;
      width: 30px;
      height: 30px;

      &-svg{
        width: 30px;
        height: 30px;
        path{
          fill: $color-dark-red;
        }
      }
    }

    &__item{
      height: 100%;
      width: 100%;
      overflow: scroll;
      display: none;

      &.active{
        display: block;
        overflow: hidden;
        &.animate{
          img{
            animation: movingUp ease-in-out 25s infinite;
          }
        }
      }

      img{
        width: 100%;
        max-height: none;
      }
      video{
        width: 100%;
        height: 100vh;
      }
    }
  }

  .icon-close{}

  .player{

    &__start-btn{
      width: 50px;
      height: 50px;
      z-index: 3;
      margin-right: 10px;
      position: absolute;
      bottom: 10px;
      left: 10px;
      background-color: transparent;
      @extend .ch-audio__transition;
      .play{
        display: block;
      }
      .pause{
        display: none;
      }
      svg{
        width: 50px;
        height: 50px;
      }
    }

    &__progress{
      display: none;
      flex: 1 0 auto;
      position: relative;
      z-index: 2;
      background-color: rgba($color-dark-red, 0.2);
      height: 0px;

      &-preload,
      &-load{
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 0px;
        transition: width 200ms linear;
      }
      &-preload{
        background-color: rgba($color-pink, 0.5);
      }
      &-load{
        z-index: 3;
        background-color: $color-pink;
      }
    }

    &__skip{
      position: absolute;
      top: 48.5%;
      left: 0;
      right: 0;
        &-btn{
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        z-index: 3;
        @extend .ch-audio__transition;
      }
    }

    &__icon-svg{
      path{
        fill: $color-yellow;
      }
      &.svg--play_circle_outline,
      &.svg--pause_circle_outline{
        path{
          fill: $color-dark-red;
        }
      }
    }

    &__src{
      display: none;
    }
  }
}


@keyframes movingUp {
  0%, 100%{
    margin-top: 0px;
  }
  50%{
    margin-top: -500px;
  }
}

@keyframes zoomIn {
    0% {
        transform: scale(0,0);
    }
    75% {
        transform: scale(1.1,1.1);
    }
    100% {
        transform: scale(1,1);
    }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
