.faq__question {
    @include h6;
    margin-bottom: $grid-gutter-small;
}

.faq__answer {
    padding-left: rem(22px);
    position: relative;

    // "A:"
    &::before {
        @include h6;
        content: 'A:';
        display: inline;
        left: 0;
        line-height: 1.25;
        position: absolute;
        top: 0;
    }
}
