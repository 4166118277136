// sharp grotesk 19

@include font-face('Sharp Grotesk 19', 'sharp-grotesk/SharpGroteskBook-19');
@include font-face('Sharp Grotesk 19', 'sharp-grotesk/SharpGroteskBookItalic-19', $style: italic);
@include font-face('Sharp Grotesk 19', 'sharp-grotesk/SharpGroteskMedium-19', weight(medium));
@include font-face('Sharp Grotesk 19', 'sharp-grotesk/SharpGroteskMediumItalic-19', weight(medium), italic);
@include font-face('Sharp Grotesk 19', 'sharp-grotesk/SharpGroteskSemibold-19', weight(semibold));
@include font-face('Sharp Grotesk 19', 'sharp-grotesk/SharpGroteskSemiboldItalic-19', weight(semibold), italic);


// sharp grotesk 20

@include font-face('Sharp Grotesk 20', 'sharp-grotesk/SharpGroteskSemibold-20', weight(semibold));
@include font-face('Sharp Grotesk 20', 'sharp-grotesk/SharpGroteskSemiboldItalic-20', weight(semibold), italic);
@include font-face('Sharp Grotesk 20', 'sharp-grotesk/SharpGroteskBold-20', weight(bold));
@include font-face('Sharp Grotesk 20', 'sharp-grotesk/SharpGroteskBoldItalic-20', weight(bold), italic);


// noe text

@include font-face('Noe Text', 'noe-text/NoeTextRegular');
@include font-face('Noe Text', 'noe-text/NoeTextRegularItalic', $style: italic);
@include font-face('Noe Text', 'noe-text/NoeTextBook', weight(medium));
@include font-face('Noe Text', 'noe-text/NoeTextBookItalic', weight(medium), italic);
@include font-face('Noe Text', 'noe-text/NoeTextBold', weight(bold));
@include font-face('Noe Text', 'noe-text/NoeTextBoldItalic', weight(bold), italic);


// noe display

@include font-face('Noe Display', 'noe-display/NoeDisplayRegular');
@include font-face('Noe Display', 'noe-display/NoeDisplayRegularItalic', $style: italic);
@include font-face('Noe Display', 'noe-display/NoeDisplayMedium', weight(medium));
@include font-face('Noe Display', 'noe-display/NoeDisplayMediumItalic', weight(medium), italic);
@include font-face('Noe Display', 'noe-display/NoeDisplayBold', weight(bold));
@include font-face('Noe Display', 'noe-display/NoeDisplayBoldItalic', weight(bold), italic);


// symbolset

@include font-face('SS Social', 'ss-social/SSSocialRegular');
