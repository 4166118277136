.related-film-links {
    padding-top: 30px;
    width: 100%;

    @media (min-width: 835px) {
        max-width: 194px;
        width: 100%;
    }

    li {
        margin-bottom: 15px;
    }
    .related-film-link{
        border: 1px solid #ff3a20;
        color: #ff3a20;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 25px 4px 5px;
        font-size: 0.8125rem;
        font-family: "Sharp Grotesk 19", sans-serif;
        text-align: center;
        text-transform: uppercase;
        transition: background-color 0.25s, color 0.25s, border-color 0.25s;
        position: relative;
        min-height: 40px;


        &:after {
            content: '\A0\A0\2192';
            display: inline;
            font-weight: 400;
            position: absolute;
            right: 15px;
        }

        &:hover {
            background: #e62b12;
            border-color: #e62b12;
            color: #f7f2f3;
        }
    }
}
