article.informational {
    .active {
        display: block;
    }

    .inactive {
        display: none;
    }
}

.loading {
    top: 0;
    left: 0;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    &.hidden {
      display: none;
    }
    .loader,
    .loader:before,
    .loader:after {
      background: #e62b12;
      -webkit-animation: load_google_album 1s infinite ease-in-out;
      animation: load_google_album 1s infinite ease-in-out;
      width: 10px;
      height: 20px;
    }
    .loader {
      color: #e62b12;
      text-indent: -9999em;
      margin: 88px auto;
      position: relative;
      font-size: 14px;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
    .loader:before,
    .loader:after {
      position: absolute;
      top: 0;
      content: '';
    }
    .loader:before {
      left: -1.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    .loader:after {
      left: 1.5em;
    }
}


@keyframes load_google_album {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 30px;
  }
  40% {
    box-shadow: 0 -2em;
    height: 40px;
  }
}
