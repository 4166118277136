.column {
    @include column;
}

.column--ruled {
    @include column-ruled($color-lola);
}

.column--no-rule {
    @include column-no-rule;
}

.column--seperator {
    border-right: 1px solid $color-pink;
    height: rem(46px);
    width: 0;
}

.column--auto {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    width: auto;
}

.column--grow {
    flex-grow: 1;
    flex-shrink: 0;
}

.column--shrink {
    flex-grow: 0;
    flex-shrink: 1;
}

.column--gutter {
    padding-right: $grid-gutter;
}

.column--large-gutter {
    padding-right: $grid-gutter-large;
}

.column--no-gutter {
    padding-right: 0;
}

.column--flush {
    padding-left: 0;
}

@for $i from 1 through $grid-columns {
    .column--#{$i} {
        width: column($i);
    }
}

@each $breakpoint in map-keys($breakpoints) {
    @include breakpoint($breakpoint) {
        @for $i from 1 through $grid-columns {
            .column--#{$i}-#{$breakpoint} {
                width: column($i);
            }
        }

        .column--ruled-#{$breakpoint} {
            @include column-ruled($color-lola);
        }

        .column--no-rule-#{$breakpoint} {
            @include column-no-rule;
        }

        .column--auto-#{$breakpoint} {
            flex-basis: auto;
            flex-grow: 1;
            flex-shrink: 1;
            width: auto;
        }

        .column--grow-#{$breakpoint} {
            flex-grow: 1;
            flex-shrink: 0;
        }

        .column--shrink-#{$breakpoint} {
            flex-grow: 0;
            flex-shrink: 1;
        }

        .column--gutter-#{$breakpoint} {
            padding-right: $grid-gutter;
        }

        .column--large-gutter-#{$breakpoint} {
            padding-right: $grid-gutter-large;
        }

        .column--no-gutter-#{$breakpoint} {
            padding-right: 0;
        }

        .column--flush-#{$breakpoint} {
            padding-left: 0;
        }
    }
}
