.link-list--more {
    @include text-button;
    color: $color-red;
}

.link-list__item {
    margin-top: $grid-gutter-small;

    &:first-child {
        margin-top: 0;
    }
}
