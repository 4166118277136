$toggle-size: $header-nav-height-large;
$submit-size: $grid-gutter * 2;
$dropdown-size: $grid-gutter-xlarge;

$toggle-expand-speed: .45s;
$dropdown-expand-speed: .33s;
$submit-expand-speed: .25s;
$clear-reveal-speed: .25s;

.header-search {
    flex: 0 0 auto;

    &#{$state-active} {
        .header-search__clear {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.header-search__toggle {
    @include flex-align-center;
    height: $toggle-size;
    width: $toggle-size;
    z-index: 2;
}

.header-search__magnifier-svg {
    .svg__path {
        fill: $color-peach;
    }
}

.header-search__bar {
    position: relative;
}

.header-search__text {
    display: block;
    height: $toggle-size;
    padding-right: $toggle-size;
    width: 100%;

    &::placeholder {
        @include font-family(sharp-grotesk-19);
    }
}

.header-search__clear {
    @include flex-align-center;
    height: $toggle-size;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $speed-fast;
    width: $toggle-size;
}

.header-search__clear-svg {
    .svg__path {
        stroke: $color-peach;
    }
}

.header-search__dropdown {
    background-color: $color-ebb;
    display: none;
    height: 0;
    overflow: hidden;
    position: relative;
    transition: height $dropdown-expand-speed linear, box-shadow $dropdown-expand-speed;
    transition-delay: $submit-expand-speed;
}

.header-search__checkboxes {
    align-items: center;
    display: flex;
    height: $dropdown-size;
    padding: $grid-gutter-xsmall $grid-gutter;
}

.header-search__submit {
    @include font-size(small);
    @include font-family(sharp-grotesk-19);
    display: block;
}

@include breakpoint(below-large) {
    .header-search {
        @include flex-align-center;
        flex-wrap: wrap;

        &#{$state-filled} {
            .header-search__submit {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    .header-search__text {
        flex: 0 0 auto;
        text-align: center;
        width: 190px;

        &::placeholder {
            color: $color-peach;
            opacity: .5;
        }
    }

    .header-search__submit {
        background-color: $color-peach;
        color: $color-red;
        flex: 1 0 100%;
        height: $submit-size;
        margin-top: $grid-gutter;
        opacity: 0;
        pointer-events: none;
        transition: opacity $speed-medium ease $speed-fast;
        width: 100%;
    }

    .header-search__clear-svg {
        .svg__path {
            stroke: $color-yellow;
        }
    }
}

@include breakpoint(large) {
    .header-search {
        flex: 0 0 auto;
        height: $toggle-size;
        overflow: hidden;
        position: absolute;
        right: -($toggle-size + $grid-gutter);
        top: 0;
        transition: width $toggle-expand-speed linear, height $toggle-expand-speed linear, right $toggle-expand-speed linear;
        transition-delay: $dropdown-expand-speed + $submit-expand-speed;
        width: $toggle-size;

        &#{$state-active} {
            height: $toggle-size + $dropdown-size + $submit-size;
            right: 0;
            transition-delay: 0s;
            width: 100%;

            .header-search__magnifier-svg {
                .svg__path {
                    fill: $color-red;
                    transition-delay: $toggle-expand-speed + $dropdown-expand-speed;
                }
            }

            .header-search__dropdown {
                box-shadow: 0 rem(14px) rem(17px) 0 rgba($color-bazaar, .3);
                height: $dropdown-size;
                transition-delay: $toggle-expand-speed;
            }

            .header-search__submit {
                height: $submit-size;
                transition-delay: $toggle-expand-speed + $dropdown-expand-speed;
            }
        }
    }

    .header-search__bar,
    .header-search__submit {
        margin-left: $toggle-size;
    }

    .header-search__toggle {
        left: 0;
        position: absolute;
        top: 0;
    }

    .header-search__magnifier-svg {
        .svg__path {
            fill: $color-black;
        }
    }

    .header-search__text {
        @include font-size(normal);
        border-bottom: 1px solid $color-pink;

        &::placeholder {
            color: transparent;
        }
    }

    .header-search__clear-svg {
        .svg__path {
            stroke: $color-pink;
        }
    }

    .header-search__dropdown {
        display: block;
    }

    .header-search__submit {
        background-color: $color-bazaar;
        color: $color-peach;
        font-size: rem(12px);
        height: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        text-align: center;
        top: $toggle-size + $dropdown-size;
        transition: height $submit-expand-speed linear;
        width: calc(100% - #{$toggle-size});
    }
}
