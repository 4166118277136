.gallery.public-update__ch-photo{
  position: relative;
  width: 100%;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px){
    flex-direction: row;
  }

  .ch-photo{
    &__image{
      position: relative;
      margin: 0.5rem;
      cursor: pointer;
      flex: 1 0 auto;
      text-align: center;

      img{
        width: auto;
        transition: all .2s ease-in-out;
        &:hover,
        &:focus{
          transform: scale(1.1);
        }
      }

      &.open{
        flex-basis: auto;
        img{
          height: 95vh;
          margin: 1rem;
        }

        .ch-photo__wrap{
          width: auto;
          height: 100%;
        }
      }
    }

    &__wrap{
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    @media (min-width: 768px) {
      &__image{
        img {
          height: 75vh;

          &:hover,
          &:focus {
            height: 80vh;
            transform: none;
          }
        }
      }

      &__wrap{
        display: block;
        overflow: auto;
      }
    }
  }
}
