.list--single-line {
    @include list-single-line;

    &.list--tight {
        line-height: 1;

        > * {
            margin-right: $grid-gutter-small;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &.list--xtight {
        line-height: 1;

        > * {
            margin-right: $grid-gutter-xsmall;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.list--ruled {
    @include list-horizontal-ruled(transparentize($color-lola, 0.75));

    &.list--tight {
        > * {
            // rule
            &::after {
                right: -$grid-gutter-small / 2;
            }
        }
    }

    &.list--xtight {
        > * {
            // rule
            &::after {
                right: -$grid-gutter-xsmall / 2;
            }
        }
    }

    &.list--dark {
        > * {
            // rule
            &::after {
                background-color: $color-peach;
            }
        }
    }
}
