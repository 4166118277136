$badge-types: (
    'blog',
    'feature-film',
    'short-film',
    'interactive',
    'podcast'
);

.badge {
    &::after {
        background-repeat: no-repeat;
        background-size: 100% auto;
        content: '';
        display: block;
        height: rem(74px);
        left: $grid-gutter-small;
        position: absolute;
        top: $grid-gutter-small;
        transition: background-image $speed-fast;
        width: rem(16px);
    }
}

@each $type in $badge-types {
    .badge--#{$type} {
        @if $type == 'feature-film' or $type == 'short-film' {
            $type: 'film';
        }

        &::after {
            @include background-image('badge-' + $type + '.svg', false);
        }

        &#{$state-streaming} {
            &::after {
                @include background-image('badge-' + $type + '-active.svg', false);
            }
        }

        @if $type != 'film' {
            &:hover {
                &::after {
                    @include background-image('badge-' + $type + '-active.svg', false);
                }
            }
        }
    }
}
