// padding-bottom

.gutter--y {
    padding-bottom: $grid-gutter;
}

.gutter--xlarge-y {
    padding-bottom: $grid-gutter-xlarge;
}

.gutter--large-y {
    padding-bottom: $grid-gutter-large;
}

.gutter--medium-y {
    padding-bottom: $grid-gutter-medium;
}

.gutter--small-y {
    padding-bottom: $grid-gutter-small;
}

.gutter--xsmall-y {
    padding-bottom: $grid-gutter-xsmall;
}

.gutter--xxsmall-y {
    padding-bottom: $grid-gutter-xxsmall;
}

// padding-right

.gutter--x {
    padding-right: $grid-gutter;
}

.gutter--xlarge-x {
    padding-right: $grid-gutter-xlarge;
}

.gutter--large-x {
    padding-right: $grid-gutter-large;
}

.gutter--medium-x {
    padding-right: $grid-gutter-medium;
}

.gutter--small-x {
    padding-right: $grid-gutter-small;
}

.gutter--xsmall-x {
    padding-right: $grid-gutter-xsmall;
}

.gutter--xxsmall-x {
    padding-right: $grid-gutter-xxsmall;
}

@each $breakpoint in map-keys($breakpoints) {
    @include breakpoint($breakpoint) {
        // padding-bottom

        .gutter--y-#{$breakpoint} {
            padding-bottom: $grid-gutter;
        }

        .gutter--large-y-#{$breakpoint} {
            padding-bottom: $grid-gutter-large;
        }

        .gutter--medium-y-#{$breakpoint} {
            padding-bottom: $grid-gutter-medium;
        }

        .gutter--small-y-#{$breakpoint} {
            padding-bottom: $grid-gutter-small;
        }

        // padding-right

        .gutter--x-#{$breakpoint} {
            padding-right: $grid-gutter;
        }

        .gutter--large-x-#{$breakpoint} {
            padding-right: $grid-gutter-large;
        }

        .gutter--medium-x-#{$breakpoint} {
            padding-right: $grid-gutter-medium;
        }

        .gutter--small-x-#{$breakpoint} {
            padding-right: $grid-gutter-small;
        }
    }
}
