$date-shadow-size: $grid-gutter-small / 2;

.date {
    border: 1px solid $color-black;
    color: $color-black;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: rem(74px);
}

.date--corner {
    background-color: $color-peach;
    bottom: 0;
    box-shadow:
    $date-shadow-size #{-$date-shadow-size} 0 $date-shadow-size $color-peach,
    $date-shadow-size $date-shadow-size 0 $date-shadow-size $color-peach;
    left: 0;
    position: absolute;
}

.date__month {
    background-color: $color-black;
    color: $color-peach;
    display: block;
    flex: 0 0 auto;
    font-family: family(sharp-grotesk-19);
    font-size: rem(11px);
    font-weight: weight(regular);
    line-height: 1.35;
    padding-bottom: $grid-gutter-xxsmall;
    padding-top: $grid-gutter-xxsmall;
}

.date__day {
    @include flex-align-center;
    @include font-family(noe-display);
    @include font-weight(medium);
    display: flex;
    flex: 1 0 auto;
    font-size: rem(42px);
    line-height: 1.3;
}
