.stack {
    > * {
        margin-top: $grid-gutter;

        &:first-child {
            margin-top: 0;
        }
    }
}

.stack--consistent {
    > *:first-child {
        margin-top: $grid-gutter;
    }
}


.stack--small {
    > * {
        margin-top: $grid-gutter-small;

        &:first-child {
            margin-top: 0;
        }
    }

    &.stack--consistent {
        > *:first-child {
            margin-top: $grid-gutter-small;
        }
    }
}


.stack--medium {
    > * {
        margin-top: $grid-gutter-medium;

        &:first-child {
            margin-top: 0;
        }
    }

    &.stack--consistent {
        > *:first-child {
            margin-top: $grid-gutter-medium;
        }
    }
}
