.display-inline {
    display: inline;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

.display-flex {
    display: flex;
}

.display-inline-flex {
    display: inline-flex;
}
