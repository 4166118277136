// only display content to screen readers
// http://a11yproject.com/posts/how-to-hide-content/

.screen-reader {
    @include hide-visually;
}

// only display content when it's focused
// useful for 'skip to main content' links
// http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1

.screen-reader--focusable {
    &:active,
    &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}
