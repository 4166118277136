.page-error {
    @include background-image('question-mark.svg');
    @include flex-align-center;
    background-position: 50% 0;
    background-size: contain;
    color: $color-bazaar;
    flex-direction: column;
    height: rem(480px);
    max-height: 92vh;
    text-align: center;

    + * {
        margin-top: -$header-height;
    }
}

.page-error__code {
    @include font-family(sharp-grotesk-19);
    @include letter-spacing(xlarge);
    margin-top: -$header-height / 2;
}

.page-error__message {
    @include font-family(noe-display);
    font-size: rem(64px);
    line-height: 1.1;
}

@include breakpoint(medium) {
    .page-error {
        height: rem(750px);
    }

    .page-error__message {
        font-size: rem(86px);
    }
}

@include breakpoint(large) {
    .page-error__message {
        font-size: rem(100px);
    }
}
