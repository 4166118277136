.stream__block {
    margin-top: $grid-gutter-large;
    code {
        visibility: hidden;
        height: 0;
        position: absolute;
    }
}

.stream__block--full-bleed {
    margin-left: -$grid-gutter;
    width: 100vw;
}

.informational.print-True {
    .informational__print_block {
        display: none;
    }
}

@include breakpoint(medium) {
    .stream--informational {
        .stream__block {
            padding-left: column(5);
            padding-right: column(1);

            &:first-child {
                margin-top: 0;
            }
        }

        .stream__block--large,
        .stream__block--full-bleed {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .stream__block--full-bleed {
        margin-left: -$grid-gutter-xxlarge;
    }
}

@include breakpoint(xxlarge) {
    .stream__block--full-bleed {
        margin-left: -$grid-gutter-xxxlarge;
    }
}
