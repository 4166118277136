$image-xsmall-size: rem(32px);

.image--fill {
    display: block;
    width: 100%;
}

.image--xsmall {
    display: block;
    height: $image-xsmall-size;
    width: $image-xsmall-size;
}

.image--circular {
    border-radius: 50%;
    display: block;
}
