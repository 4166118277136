.media__caption {
    @include text-caption;
    color: $color-bazaar;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: $grid-gutter-xsmall;
    max-width: calc(100vw - #{$grid-gutter * 2});
}

.media__credit {
    color: $color-pink;
}

@include breakpoint(medium) {
    .media__caption {
        max-width: calc(100vw - #{$grid-gutter-xxlarge * 2});
    }
}

@include breakpoint(xxlarge) {
    .media__caption {
        max-width: calc(100vw - #{$grid-gutter-xxxlarge * 2});
    }
}
