.hero {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - #{$header-height + $grid-gutter-medium});
    padding-top: $grid-gutter-large;
    position: relative;
}

.hero--featured {
    border-bottom: 1px solid $color-lola;
}

.hero--short {
    min-height: rem(550px);

    &.hero--featured {
        margin-bottom: $grid-gutter;

        .hero__background {
            top: $grid-gutter;
        }
    }
}

.hero--light,
.hero--dark {
    .button--outline {
        background-color: transparentize($color-black, .65);

        &:hover {
            background-color: transparentize($color-black, .45);
            border-color: transparentize($color-black, .45);
        }
    }
}

.hero--light {
    color: $color-peach;
}

.hero--background {
    .hero__title,
    .hero__icon {
        margin-bottom: $grid-gutter;
    }

    .hero__title {
        line-height: 1.2;  // compensate for highlight
    }

    .hero__title-text,
    .hero__rich-text {
        background-color: $color-peach;
    }

    .background-ebb & {
        .hero__title-text,
        .hero__rich-text {
            background-color: $color-ebb;
        }
    }
}

.hero--behind-header,
.hero--behind-header-with-gradient {
    .hero__background {
        left: 0;
        right: 0;
    }
}

.hero--behind-header-with-gradient {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $header-height-large * 2;
        background: linear-gradient(to bottom, $color-peach 40%, transparentize($color-peach, 0.1) 50%, transparentize($color-peach, 0.65) 75%, transparentize($color-peach, 1));
        pointer-events: none;
    }
}

.hero--image-top-left {
    .hero__background {
        background-position: 0 0;
    }
}

.hero--text-left {
    flex-direction: column;
    justify-content: flex-end;
}

.hero--text-right,
.hero--text-bottom-right {
    justify-content: flex-end;

    .hero__text {
        align-items: flex-start;
    }

    .hero__title {
        margin-right: $grid-gutter-medium;
    }

    .hero__rich-text {
        padding-right: $grid-gutter-medium;
    }
}

.hero--text-right {
    align-items: flex-end;
}

.hero--text-bottom-left,
.hero--text-bottom-right {
    align-items: flex-end;
    padding-bottom: 0;

    &.hero--background {
        .hero__text {
            position: relative;
            top: $grid-gutter-small;
        }

        .hero__title,
        .hero__icon {
            margin-bottom: 0;
        }
    }
}

.hero__background {
    background-color: $color-pink;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: $grid-gutter;
    position: absolute;
    right: $grid-gutter;
    top: 0;
}

.hero__date {
    background-color: $color-pink;
    border-color: $color-peach;
    color: $color-peach;
    left: $grid-gutter * 2;
    position: absolute;
    top: $grid-gutter * 2;

    .date__month {
        background-color: $color-peach;
        color: $color-pink;
    }
}

.hero__image {
    display: block;
    height: 100%;
    visibility: hidden;
    width: 100%;
}

.hero__badge {
    opacity: .5;
    position: absolute;
    right: $grid-gutter * 2;
    top: $grid-gutter;
}

.hero__text {
    @include z-index(body);
    box-sizing: content-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    margin-left: -$grid-gutter;
    padding-left: $grid-gutter;
    width: 100%;
}

.hero__icon {
    align-self: flex-start;
    margin-left: $grid-gutter;
}

.hero__title {
    @include h1;
    display: block;
    line-height: 1;
}

.hero__title--headline {
    @include h2;
}

.hero__title--film {
    @include text-film-title;
}

.hero__title--subtitle {
    @include h4;
}

.hero__title-text {
    @include highlight(transparent);

    .hero__title--subtitle & {
        padding: 5px 10px;
        line-height: 1.3;
    }
}

.hero__rich-text {
    padding: $grid-gutter;
}

.hero__button {
    @include flex-align-center;
    @include text-button-small;
    color: $color-yellow;
    height: rem(130px);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: color $speed-fast;
    width: rem(80px);

    &:hover {
        color: $color-black;

        .svg__path {
            fill-opacity: .5;
        }
    }
}

.hero__button--launch {
    height: rem(75px);
    width: rem(72px);

    .hero__button-text {
        transform: translate(-50%, -50%);
    }
}

.hero__button-text {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-68%, -50%);
}

@mixin hero-text-offset ($gutter) {
    .hero--text-left,
    .hero--text-bottom-left {
        .hero__text {
            margin-left: -$gutter;
            padding-left: $gutter;
        }

        .hero__title-text,
        .hero__rich-text {
            padding-left: $gutter;
        }
    }

    .hero--text-right,
    .hero--text-bottom-right {
        .hero__text {
            margin-right: -$gutter;
            padding-right: $gutter;
        }

        .hero__icon,
        .hero__title-text {
            margin-left: -$gutter;
            margin-right: $gutter;
        }

        .hero__icon {
            padding-left: $grid-gutter;
        }

        .hero__title {
            margin-right: $gutter;
        }

        .hero__rich-text {
            padding-right: $gutter;
        }
    }

    .hero__date {
        left: $gutter;
        top: $gutter;
    }
}

@include breakpoint(below-small) {
    .hero--light,
    .hero--dark {
        .hero__title,
        .hero__rich-text {
            margin-left: $grid-gutter;
        }
    }
}

@include breakpoint(below-medium) {
    .hero{
        min-height: 0;
        padding-top: 52%;
        &__background{
            bottom: auto;
        }
        &__text{
            color: $color-black;
            .button{
                max-width: none;
            }
            .button--outline{
                background-color: transparent;
            }
        }
    }
    .hero--text-below {
        display: block;
        min-height: 0;
        padding-top: 0;

        .hero__background,
        .hero__player {
            @include aspect-ratio(16, 9);
        }

        .hero__background {
            bottom: auto;
            left: auto;
            right: auto;
            top: auto;

            .hero__image {
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .hero__player {
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .hero__text {
            padding-top: $grid-gutter;
            top: auto;
        }

        .hero__title {
            margin-bottom: $grid-gutter / 3;
        }

        .hero__title-text {
            background-color: transparent;
            padding: 0;
        }

        .hero__title-text,
        .hero__rich-text {
            padding-left: $grid-gutter;
            padding-right: $grid-gutter;
        }

        .hero__rich-text {
            padding-top: $grid-gutter-small;
        }

        &.hero--featured {
            border-bottom: 0;
            margin-bottom: 0;
        }

        &.hero--background {
            .hero__text {
                top: auto;
            }
        }

        &.hero--short {
            min-height: 0;
        }
    }

    .hero--behind-header-with-gradient {
        &::after {
            display: none;
        }
    }
}

@include breakpoint(medium) {
    .hero {
        min-height: calc(100vh - #{$header-height-large + $grid-gutter-medium});
        padding-bottom: $grid-gutter-large;
    }

    .hero--light,
    .hero--dark {
        .hero__rich-text {
            padding-bottom: 0;
        }
    }

    .hero--short {
        min-height: rem(550px);
    }

    .hero--behind-header,
    .hero--behind-header-with-gradient {
        margin-top: -$header-height-large;
        min-height: calc(100vh - #{$grid-gutter-medium});
    }

    .hero--text-bottom-left,
    .hero--text-bottom-right {
        padding-bottom: 0;
    }

    @include hero-text-offset($grid-gutter-xxlarge);

    .hero--text-left {
        justify-content: center;

        .hero__text {
            max-width: column(4);
        }
    }

    .hero--text-right {
        align-items: flex-end;

        .hero__title {
            padding-right: column(2);
        }
    }

    .hero__text {
        max-width: column(5);
    }

    .hero__rich-text {
        // adjust width to align inner text with grid
        width: calc(100% + #{$grid-gutter});
    }
}

@include breakpoint(large) {
    .hero--text-right,
    .hero--text-bottom-right {
        .hero__rich-text {
            .column {
                padding-bottom: 0;
            }
        }
    }
}

@include breakpoint(xxlarge) {
    @include hero-text-offset($grid-gutter-xxxlarge);

    .hero__date {
        top: $grid-gutter-xxlarge;
    }
}
