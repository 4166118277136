.public-update__keys{
    position: relative;

  .keys{
    &__map{
      display: none;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 4;

      &.open{
        display: flex;
        background-color: rgba(0,0,0,0.8);
      }
    }
    &__close{
      position: fixed;
      top: 10px;
      right: 10px;
      width: 50px;
      height: 50px;
      background-color: $color-ebb;
      text-align: center;
      padding-top: 12px;
      cursor: pointer;
    }
    &__container{
      list-style: none;
      padding: 0;
      margin: 0;
      h3{
        @include h3;
        color: $color-peach;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $color-peach;
      }
      li{
        padding: 0;
        margin: 1rem 0;
        color: $color-peach;
        font-size: 1.5rem;
      }
      kbd{
        display: inline-block;
        text-align: center;
        background-color: $color-ebb;
        color: $color-black;
        min-width: 60px;
        border-radius: 3px;
        padding: 0.2rem;
        margin-right: 0.5rem;
        font-size: 1.2rem;
        @include font-family('sharp-grotesk-19');
        box-shadow: 1px 3px 0px $color-bazaar;
      }
    }
    &__info{
      cursor: pointer;
      position: absolute;
      bottom: 20px;
      right: 20px;
      svg{
        width: 60px;
        height: 60px;
        path:first-child{
          fill: none;
        }
        path+path{
          fill: $color-dark-red;
        }
      }
    }
  }
}
