.album.public-update__ch-photo{
  display: grid;
  height: 100%;
  grid-gap: 0;
  // grid-template-columns: repeat(3, 33.33%);
  grid-template-columns: repeat(5, 20%);
  grid-row: auto;
  grid-auto-flow: row dense;
  transition: all 500ms ease-in-out;

  .ch-photo{
    &__wrap{
      position: relative;
      width: 100%;
    }

    &__image{
      position: relative;
      margin: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 300ms ease-in-out;
      cursor: pointer;

      &:hover{
        margin: 0;
        img{
          filter: none;
        }
      }

      img{
        // z-index: 1;
        width: 100%;
        max-height: none;
        filter: grayscale(100%);
        transition: all 300ms ease-in-out;
      }

      &:nth-child(6n+5){
        grid-row-end: span 2;
        grid-column-end: span 2;
      }

      &.first{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }

      &.open{
        grid-row-end: span 5;
        grid-column-end: span 5;

        img{
          height: 95vh;
          width: auto;
          box-shadow: 0 0 20px rgba(0,0,0,0.5);
          margin: 1rem;
          filter: none;
        }

        .ch-photo__wrap{
          width: auto;
          height: 100%;
        }
      }
    }
  }
}
