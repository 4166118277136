.pswd-required {
    h1 {
        font-family: "Sharp Grotesk 20", sans-serif;
        text-transform: none;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.156;
        text-align: center;
        margin-top: 100px;
    }
    p {
        text-align: center;
        margin-top: 20px;
    }

    form {
        display: flex;
        flex-direction: column;
        max-width: 300px;
        width: 100%;
        margin: 50px auto;

        .inputs {
            input {
                border: 1px solid #e6dddf;
                margin-left: 10px;
                padding: 5px;
            }

            .errorlist {
                li {
                    color: #ff3a20;
                }
            }
        }

        input[type=submit] {
            margin: 15px auto;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
