$secondary-nav-height: rem(60px);

.secondary-nav {
    @include text-secondary-nav;
    border-bottom: 1px solid $color-lola;
    height: $secondary-nav-height;

    ~ #app { // sass-lint:disable-line no-ids
        .filters {
            border-top-color: transparent;
        }
    }
}

.secondary-nav--featured {
    .secondary-nav__item {
        &#{$state-active} {
            border-bottom-color: $color-ebb;

            .secondary-nav__link {
                background-color: $color-ebb;
            }
        }
    }
}

.secondary-nav__list {
    .flickity-viewport {
        overflow: visible;

        .flickity-slider {
            @media (max-width: 450px) {

            }
        }
    }
}

.secondary-nav__item {
    border: 1px solid $color-lola;
    color: $color-pink;
    flex: 0 0 auto;
    height: $secondary-nav-height;
    transition: color $speed-fast;

    &:last-child(:first-child) {
        border-left: none;
    }

    &#{$state-active} {
        border-bottom: 1px solid $color-peach;
        color: $color-red;
    }
}

.secondary-nav__link {
    @include flex-align-center;
    background-color: $color-peach;
    display: flex;
    height: 100%;
    padding: 0 $grid-gutter;
    white-space: nowrap;
}

@media (max-width: 450px) {
    .secondary-nav {
        height: auto;
        .flickity-viewport {
            .flickity-slider {
                display: flex;
                flex-direction: column;
                position: relative;
            }
        }

        &__item {
            position: relative !important;
            left: 0 !important;
        }
    }
}
