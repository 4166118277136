.thumb-grid__grid {
    position: relative;
    transition: height $speed-medium;
    width: 100%;

    .thumb {
        margin-bottom: $grid-gutter-medium;
        position: relative;
        top: 100%;

        .thumb__bottom {
            // limit height to 3 lines
            $spec: map-get($font-sizes, small);
            height: nth($spec, 1) * nth($spec, 2) * 3;
        }
    }
}

.thumb-grid .thumb,
.thumb-grid__column-sizer {
    width: 100%;
}

.thumb-grid__gutter-sizer {
    width: $grid-gutter;
}

@include breakpoint(small) {
    .thumb-grid .thumb,
    .thumb-grid__column-sizer {
        $_width: column(4);
        $_per-thumb-portion-of-total-gutters-for-row: ($grid-gutter * 2)/3;
        width: calc(#{$_width} - #{$_per-thumb-portion-of-total-gutters-for-row});
    }

    .thumb-grid__grid .thumb .thumb__bottom {
        // limit height to 4 lines
        $spec: map-get($font-sizes, small);
        height: nth($spec, 1) * nth($spec, 2) * 4;
    }
}

@include breakpoint(medium-only) {
    .thumb-grid__grid .thumb .thumb__bottom {
        // limit height to 5 lines
        $spec: map-get($font-sizes, small);
        height: nth($spec, 1) * nth($spec, 2) * 5;
    }
}

@include breakpoint(medium) {
    .thumb-grid .thumb,
    .thumb-grid__column-sizer {
        $_width: column(3);
        $_per-thumb-portion-of-total-gutters-for-row: ($grid-gutter * 3)/4;
        width: calc(#{$_width} - #{$_per-thumb-portion-of-total-gutters-for-row});
    }
}

@include breakpoint(below-small) {
    .thumb-grid__grid .thumb .thumb__bottom {
        // remove explicit height
        height: auto;
    }
}