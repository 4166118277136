.related {
    border-bottom: 1px solid $color-lola;
    border-top: 1px solid $color-lola;
    padding-bottom: $grid-gutter;
    padding-top: $grid-gutter;

    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }
}

.related__title {
    @include h7;
    color: $color-pink;
    margin-bottom: $grid-gutter;
}
