.icon {
    // sass-lint:disable-block no-important
    font-feature-settings: 'liga';
    font-style: normal !important;
    font-weight: normal !important;
    vertical-align: middle;
    white-space: nowrap;
}

.icon--social {
    font-family: family(social-icons);
}
