.color-pink {
    color: $color-pink;
}

.color-bazaar {
    color: $color-bazaar;
}

.color-red {
    color: $color-red;
}

.color-yellow {
    color: $color-yellow;
}
