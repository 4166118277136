.public-update__ch-video360{
  position: relative;
  width: 100vw;
  height: 100vh;
  
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }
}
