$section-flavors: (
    'transparent',
    'black',
    'dark-tinted',
    'tinted'
);

.section {
    overflow: hidden;
    padding-bottom: $grid-gutter-medium;
    padding-top: $grid-gutter-medium;

    &.section-content-center {
        .container {
            .row {
                justify-content: center;

                h1, h2, h3, h4, h5, h6 {
                    text-align: center;
                }
            }
        }
    }
}

.section--small {
    padding-bottom: $grid-gutter;
    padding-top: $grid-gutter;

    .section__header {
        margin-bottom: $grid-gutter;
    }
}

@each $flavor in $section-flavors {
    $section-rule-color: $color-lola;

    @if $flavor == 'dark-tinted' {
        $section-rule-color: $color-pink;
    }

    .section--#{$flavor} {
        + .section--#{$flavor} {
            padding-top: 0;

            // rule
            &::before {
                @include container;
                border-top: 3px solid $section-rule-color;
                content: '';
                display: block;
                margin-bottom: $grid-gutter-large;
            }
        }

        + .section--small {
            // rule
            &::before {
                margin-bottom: $grid-gutter + 2px;
            }
        }
    }
}

.section--black {
    background-color: $color-black;
    color: $color-peach;

    .section__eyebrow {
        color: $color-yellow;
    }

    .informational__header {
        background-color:  $color-black;
    }

    .informational {
        .nav--sub .nav__link.is-active {
            color: $color-peach;
        }
    }
}

.section--dark-tinted {
    background-color: $color-lola;

    .informational__header {
        background-color:  $color-lola;
    }
}

.section--tinted {
    background-color: $color-ebb;

    .informational__header {
        background-color:  $color-ebb;
    }
}

.section__header {
    margin-bottom: $grid-gutter-medium;
    margin-top: $grid-gutter-small;
}

.section__eyebrow {
    color: $color-red;
}

@include breakpoint(medium) {
    .section {
        padding-bottom: $grid-gutter-xxlarge;
        padding-top: $grid-gutter-large;
    }
    .section--small {
        padding-bottom: $grid-gutter-medium;
        padding-top: $grid-gutter;
    }

    body.donate {
        .page__content {
            .section {
                padding-bottom: 50px;
                &.section-embed-donate {
                    padding-top: 30px;
                }
            }
        }
    }
}

.section-embed-donate {
    .check-donate {
        p {
            margin: 20px 0;
            font-size: .8125rem;
        }

        a {
            border-bottom: 1px solid black;
            &:hover {
                border-bottom: 1px solid transparent;
                color: #e62b12;
            }
        }

        ul {
            li {
                font-size: .8125rem;
            }
        }
    }
}

.section__side{
    padding-bottom: $grid-gutter;
    padding-top: $grid-gutter;
    .container{
        margin-right: 0;
        margin-left: 0;
    }
}
