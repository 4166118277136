.modal {
    @include z-index(modal);
    background-color: $color-black;
    color: $color-peach;
    display: none;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;

    #{$state-modal-open} & {
        display: block;
    }

    .section {
        &::before {
            margin-left: $grid-gutter-small;
            margin-right: $grid-gutter-small;
        }
    }

    .carousel {
        // fixes some hovering glitches w/ flickity carousel
        backface-visibility: hidden;
        transform: translateZ(0);
    }
}

.modal__header {
    padding-bottom: $grid-gutter;
    padding-top: $grid-gutter;
    position: relative;
}

.modal__logo {
    display: block;
    margin: 0 auto;
    width: 150px;
}

.modal__logo-svg {
    .svg__path {
        fill: $color-peach;
    }
}

.modal__close {
    position: fixed;
    right: $grid-gutter * 1.5;
    top: $grid-gutter;
    z-index: 2;

    &:hover {
        .modal__close-svg {
            .svg__path {
                stroke: $color-dark-red;
            }
        }
    }
}

.modal__close-svg {
    .svg__path {
        stroke: $color-red;
    }
}

.modal__featured {
    border-bottom: 1px solid transparentize($color-lola, .75);
    border-top: 1px solid transparentize($color-lola, .75);
}

.modal__featured-text {
    padding-bottom: $grid-gutter-medium;
    padding-top: $grid-gutter;
}

.modal__related {
    padding-bottom: $grid-gutter-medium;

    .section::before {
        border-color: transparentize($color-lola, .75);
    }

    .thumb__title--type {
        display: block;
    }
}
