.link {
    transition: color $speed-fast;

    &:hover {
        color: $color-dark-red;
    }

    &#{$state-active} {
        pointer-events: none;
    }
}

.link--more {
    @include text-button;
    color: $color-red;

    &::after {
        // two non-breaking spaces + right-facing arrow
        content: '\00A0\00A0\2192';
        display: inline;
        font-weight: weight(regular);
    }
}

.link--external {
    &::after {
        @include background-image('external.svg', $position: '100% 0');
        // enough non-breaking spaces to contain external icon
        content: '\A0\A0\A0\A0\A0';
        display: inline;
        // vertical-align: text-top;
    }
}

.link--article {
    @include text-article-link;
    display: block;
}

.link__no-highlight {
    color: initial;
}
