.pullquote {
    color: $color-bazaar;
    padding-left: column(1);
}

.pullquote--small {
    padding-left: 0;

    .pullquote__text {
        font-size: rem(14px);
        line-height: 1.857;
    }

    .pullquote__attribution {
        margin-top: $grid-gutter;

        &::before {
            // em dash
            content: '\2014';
            display: inline;
        }
    }
}

.pullquote__text {
    @include text-pullquote;

    p {
        &:first-child {
            position: relative;

            &::before {
                // left double quotation mark
                content: '\201C';
                display: inline;
                position: absolute;
                right: 100%;
            }
        }

        &:last-child {
            &::after {
                // right double quotation mark
                content: '\201D';
                display: inline;
            }
        }
    }
}

.pullquote__attribution {
    @include text-caption;
    display: inline-block;
    margin-top: $grid-gutter-small;
}
