.impact-post {
    h5 {
        color: #000;
    }

    .stream__block:first-child {
        margin-top: 0;
    }

    @media print {
        .informational__header h1 {
            display: none;
        }

        h4 {
            display: block!important;
            color: #9c8387;
        }
        .stream__block:first-child {
            margin-top: 52px;
        }

        .stream__block.stream__block--text.active,
        .stream__block.stream__block--text.inactive {
            display: block;
        }
    }
}
