.ruled-title {
    @include text-eyebrow;
    display: block;
    position: relative;

    // rule
    &::after {
        background-color: $color-lola;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
}

.ruled-title__text {
    background-color: $color-peach;
    display: inline-block;
    padding-right: $grid-gutter-small;
    position: relative;
    z-index: 2;
}
