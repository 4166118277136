$author-thumb-size: 90px;

.author {
    margin-bottom: $grid-gutter;

    .rich-text {
        @include text-body-small;
    }
}

.author__name {
    @include text-eyebrow;
    margin-bottom: $grid-gutter-xsmall;
}

.author__image {
    border-radius: 50%;
    display: block;
    height: $author-thumb-size;
    width: $author-thumb-size;
}

@include breakpoint(below-medium) {
    .author {
        @include clearfix;
    }

    .author__image {
        float: right;
        margin-bottom: $grid-gutter-xsmall;
        margin-left: $grid-gutter-small;
    }
}

@include breakpoint(medium) {
    .author {
        padding-right: $grid-gutter + $author-thumb-size;
        position: relative;
    }

    .author--headshot {
        min-height: $author-thumb-size;
    }

    .author__image {
        position: absolute;
        right: 0;
        top: 0;
    }
}
