.page {
    @include font-smoothing-antialiased;
    background-color: $color-peach;
    color: $color-black;
    display: flex;
    flex-direction: column;
    font-family: family(noe-text);
    padding-top: $header-height;

    &#{$state-active},
    &#{$state-modal-open} {
        height: 100vh;
        overflow: hidden;
    }
}

.page__content {
    flex: 1 1 auto;

    + .section {
        padding-top: 0;

        // rule
        &::before {
            @include container;
            border-top: 3px solid $color-lola;
            content: '';
            display: block;
            margin-bottom: $grid-gutter-large;
        }
    }
}

@include breakpoint(medium) {
    .page {
        padding-top: $header-height-large;
    }
}
