@mixin thumb--big {
    .thumb__title {
        font-size: size(large);
    }

    .thumb__title-text {
        padding: 5px 14px 5px 0;
    }

    .thumb__button-text--verbose {
        display: block;
    }

    .thumb__button-text--short {
        display: none;
    }

    .thumb__dek {
        max-width: 500px;
        width: 75%;
    }
}

@mixin thumb--play {
    // badge
    &::after {
        display: none;
    }

    .thumb__overlay,
    .thumb__icon,
    .thumb__meta,
    .thumb__airing-text,
    .thumb__dek {
        display: none;
    }

    .thumb__play,
    .thumb__title--type {
        display: block;
    }
}

@mixin thumb--color {
    .thumb__background {
        filter: none !important; // sass-lint:disable-line no-important
    }

    .thumb__filter {
        display: none;
    }
}

@mixin thumb--dek {
    .thumb__airing-text,
    .thumb__dek {
        display: block;
    }
}

.thumb {
    display: block;
    position: relative;

    &#{$state-streaming} {
        .thumb__title--type {
            .thumb__title-text {
                color: $color-red;
            }
        }
    }

    &:hover {
        .thumb__title {
            .thumb__title-text {
                color: $color-dark-red;
            }
        }

        .thumb__overlay {
            opacity: 1;
        }

        .thumb__meta {
            opacity: 1;
            pointer-events: auto;
        }

        .thumb__play {
            .svg__path {
                fill-opacity: .5;
            }
        }
    }

    .carousel--first-big .carousel__slide:first-child &,
    .carousel--big & {
        @include thumb--big;
    }

    .carousel--play & {
        @include thumb--play;
    }

    .carousel--color & {
        @include thumb--color;
    }

    .carousel--deks & {
        @include thumb--dek;
    }
}

.thumb--big {
    @include thumb--big;
}

.thumb--play {
    @include thumb--play;
}

.thumb--color {
    @include thumb--color;
}

.thumb--dek {
    @include thumb--dek;
}

.thumb__top {
    @include aspect-ratio(16, 9);
    position: relative;
}

.thumb__background,
.thumb__text,
.thumb__overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.thumb__background {
    background-color: $color-ebb;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.thumb__image {
    display: block;
    height: 100%;
    visibility: hidden;
    width: 100%;
}

.thumb__text {
    z-index: 2;
}

.thumb__icon {
    opacity: .5;
    position: absolute;
    right: $grid-gutter-small;
    top: $grid-gutter-small;
    width: rem(32px);
}

.thumb__title {
    @include h6;
}

.thumb__title-text {
    @include highlight-small($color-peach);
    padding-left: 0;
}

.thumb__title--main {
    bottom: rem(-.24em);
    left: 0;
    line-height: 1.2;
    max-width: 43%;
    position: absolute;

    .thumb__title-text {
        padding-bottom: 0;
    }

    .section--black & {
        color: $color-peach;

        .thumb__title-text {
            background-color: $color-black;
        }
    }

    .section--tinted & {
        .thumb__title-text {
            background-color: $color-ebb;
        }
    }

    .section--dark-tinted & {
        .thumb__title-text {
            background-color: $color-lola;
        }
    }
}

.thumb__title--type {
    display: none;
    margin-top: $grid-gutter-xxsmall;

    .thumb__title-text {
        background-color: transparent;
        color: $color-pink;
    }
}

.thumb__play {
    display: none;
    height: 100%;
    opacity: 0;
    transition: opacity $speed-fast;
    width: 100%;

    .svg {
        height: rem(62px);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: rem(40px);
    }
}

.thumb__overlay {
    @include flex-align-center;
    opacity: 0;
    overflow: hidden;
    transition: opacity $speed-fast;
    z-index: 3;
}

.thumb__button-text {
    display: inline-block;
}

.thumb__button-text--verbose {
    display: none;
}

.thumb__meta {
    @include text-meta;
    align-items: center;
    background: rgba($color-black, .4);
    bottom: 0;
    color: $color-peach;
    display: flex;
    font-size: rem(8px);
    height: rem(17px);
    justify-content: flex-end;
    left: 0;
    line-height: 1;
    opacity: 0;
    padding: 0 $grid-gutter-small;
    pointer-events: none;
    position: absolute;
    transition: opacity $speed-fast;
    width: 100%;
    word-wrap: nowrap;
}

.thumb__airing-text {
    @include text-eyebrow-small;
    color: $color-red;
    margin-top: rem(15px);

    + .thumb__dek {
        margin-top: rem(5px);
    }
}

.thumb__dek {
    margin-top: rem(13px);
    max-width: 350px;
    width: 94%;
}
