.public-update__chs{
  height: 100vh;
  background-color: $color-ebb;
  padding: 0.5rem 1.5rem 1rem;
  flex-direction: column;
}
.public-update__ch{
  height: 100%;
  width: 100%;
  margin-left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  @media (max-width: 767px) {
    &.slide-intro {
      h1, h2 {
        font-size: 10vw;
      }
    }
  }

    &.image {
        display: flex;
        align-items: center;
        justify-content: center;

        .cta-btn {
            font-family: "Sharp Grotesk 19", sans-serif;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 0.8125rem;
            line-height: 1.54;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #ff3a20;
            border: 1px solid transparent;
            color: #f7f2f3;
            height: 2.5rem;
            padding: 11px 2rem 9px;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            transition: background-color 0.25s, color 0.25s, border-color 0.25s;

            &:hover {
                background-color: #e62b12;
                border-color: #e62b12;
            }
        }
    }
  .overlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(100, 87, 89, 0.5);
        z-index: 1;
    }
    .intro__volume {
      padding: 0 15px;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;

        a {
          margin: 30px 0;
        }

        &-title {
          @include h2;
          line-height: 1.2;
          text-align: center;
          font-size: 3.5rem;
          font-style: italic;
           display: block;
           line-height: 1;
           color: #fff;
        }

        &-subtitle {
            @include h2;
          line-height: 1.2;
          text-align: center;
          font-size: 3.5rem;
           display: block;
           line-height: 1;
           color: #fff;
        }

        .rich-text {

          font-size: 5vw;
          color: #fff;
          max-width: 880px;
          margin: 0 auto;

          &.align-center {
            text-align: center;
          }

          @media (min-width: 768px) {
            font-size: 22px;
          }
        }
    }

  .ch{
    &__artiest {
      .container{
        margin-top: 3rem;
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &.artist-statement {
        max-height: 0;
        overflow: auto;
        transition: max-height .35s ease-out;
        &.alt {
          max-height: 100%;
          transition: max-height .5s ease-in;

          .container{
            height: auto;
            }
        }
      }

    }

    &__artiest{
      &.alt{
        background-color: rgba(226, 212, 214, 0.5);
        position: relative;

        .container{
          height: 100%;
          margin: 0;
          padding: 0rem 2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .cta-wrapper {
                margin-top: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .info__icon {
          display: block;
        }
      }

      .info__icon {
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
        z-index: 5;
        display: none;
      }
      .svg--close {
        fill: #e62b12;
      }
    }
  }

  .artiest{
    &__name,
    &__project,
    &__statement{
      @include h2;
      line-height: 1.2;
      margin-bottom: 1rem;
      text-align: center;
      span{
        // background-color: $color-peach;
        padding: 3px 0;
      }
    }
    &__statement {
      margin-top: 30px;
    }
    &__project{
      font-size: 8vw;
      font-style: italic;

      @media (min-width: 768px) {
        font-size: 3.5rem;
      }
    }
    &__name{
      font-size: 7vw;
      @media (min-width: 768px) {
        font-size: 2.8rem;
      }
    }

    &__bio{
      margin-top: 1rem;
      font-size: 18px;
      line-height: 1.5;

      p {
        min-height: 1.5rem;
      }

      a {
        text-decoration: underline;

        &:hover {
          color: #e52c12;
        }
      }
    }

    &__image{
      align-self: center;
      margin-top: 1rem;
      img{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 0;
        background-color: $color-pink;
      }
    }
    &__link{
        margin-bottom: 4%;
    }
  }


  .artwork{
    &__background{
      display: block;
      background-color: $color-pink;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
    }

    &__image{
      display: none;
    }
  }

  &.even{
    flex-direction: row-reverse;
  }

  &.active{
    z-index: 1;

    @media (min-width: 768px) {
      overflow-y: none;
    }
  }

  &.inactive{
    visibility: hidden;
  }

  &-container{
    position: relative;
    display: flex;
    height: 92%;
    width: 100%;
    overflow: hidden;
  }

  &-content{
    min-height: 100vh;
    background-color: $color-ebb;
    padding: 0.5rem 0rem 1rem;
    display: flex;
    flex-direction: column;

    &.photo-album{
      height: auto;
    }
  }

  &-body{
    margin-top: 100px;
    h3{
      @include font-family('sharp-grotesk-20');
      color: #bca6aa;
      margin: 1rem auto;
    }
    h4{
      @include h4;
      margin-bottom: 1rem;
    }
    h5{
      @include h5;
      margin-bottom: 1rem;
    }
    .rich-text p{
      margin-bottom: 1rem;
    }
    .column{
      margin: auto;
      flex: 1;

      .rich-text {
        text-align: left;
        display: flex;
        flex-direction: column;
        max-width: 700px;
        margin: 0 auto;

        ul, ol {
          text-align: left;
        }
      }

      .artiest {
        text-align: center;
      }
    }
    .artiest{
      &__name{
        @include font-family('sharp-grotesk-19');
        letter-spacing: 1px;
        font-size: 1rem;
        text-transform: uppercase;
        line-height: 1.308;
        margin-bottom: 5px;
      }
      &__bio{}
    }
  }
}

.img-magnifier-glass {
  position: absolute;
  border: 4px solid $color-bazaar;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  z-index: 2;
  transition-property: width, height, border;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  box-sizing: border-box;

  &.limit{
    border-color: $color-dark-red;
  }

  &[data-size="1"]{
    width: 100px;
    height: 100px;
  }
  &[data-size="3"]{
    width: 300px;
    height: 300px;
  }
  &[data-size="4"]{
    width: 400px;
    height: 400px;
  }
  &[data-size="5"]{
    width: 500px;
    height: 500px;
  }
}
