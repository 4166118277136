.carousel {
    margin-left: -$grid-gutter;
    position: relative;
    width: calc(100% + #{$grid-gutter});

    .flickity-viewport {
        overflow: visible;
    }

    .flickity-prev-next-button {
        top: 50%;
    }
}

.carousel__slide {
    opacity: .2;
    padding-left: $grid-gutter;
    pointer-events: none;
    position: relative;
    transition: opacity $speed-fast;
    width: column(9);

    &#{$state-selected} {
        opacity: 1;
        pointer-events: auto;

        .thumb__play {
            opacity: 1;
        }
    }
}

@include breakpoint(small) {
    .carousel--big {
        .carousel__slide {
            width: column(8);
        }
    }

    .carousel--first-big {
        .carousel__slide {
            width: column(5);

            .thumb {
                margin-top: 36%;
            }

            &:first-child {
                width: column(8);

                .thumb {
                    margin-top: 0;
                }
            }
        }
    }

    .carousel--uniform {
        .carousel__slide {
            width: column(5);
        }
    }
}

@include breakpoint(medium) {
    .carousel--uniform {
        .carousel__button,
        .carousel__slide {
            width: column(4);
        }
    }
}

@include breakpoint(large) {
    .carousel--big {
        .carousel__button,
        .carousel__slide {
            width: column(5);
        }
    }

    .carousel--first-big {
        .carousel__slide {
            width: column(3);

            .thumb {
                margin-top: 40%;
            }

            &:first-child {
                width: column(5);

                .thumb {
                    margin-top: 0;
                }
            }
        }
    }

    .carousel--uniform {
        .carousel__button,
        .carousel__slide {
            width: column(3);
        }
    }
}
