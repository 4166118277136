.public-update__vol{
  &-artwork{
    height: 80vh;
    position: relative;
    .intro__background{
      left: 0;
      right: 0;
    }
  }
}
