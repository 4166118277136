.public-update__footer,
.public-update__intro{
  .intro__text{
    display: flex;
    align-items: center;

    .rich-text{
      font-size: 22px;
    }
  }

  .scroll-down-intro {
    position: absolute;
    transform: rotate(90deg);
    bottom: 0;
    left: 50%;
    margin-left: -29px;

    svg {
      width: 50px;
      fill: #e62b12;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .stream--interactive {
    text-align: center;

    .rich-text--custom-columns {
        display: flex;
        gap: 50px;
        flex-direction: column;

        .column {
            margin: 0;
            padding: 0;
            flex: 1;
        }

        @media (min-width: 768px) {
            gap: 30px;
            flex-direction: row;
        }
    }


    .stream__block{
        ul {
            text-align: left;

            li {
                padding-left: 10px;
            }
        }
        &--full-bleed.stream__block--image {
            max-width: 100%;
            width: 100%;
            margin: 0;

            figure.media {
                max-height: 1000px;
                overflow: hidden;
            }
        }
        &--text {
            text-align: left;
        }
        &--image {
            min-height: 200px;
            max-width: 225px;
            margin: 0 20px;
            display: inline-block;
            vertical-align: middle;

            figure.media {
                display: flex;
                min-height: 200px;
                align-items: center;
            }
        }
    }
  }
}

.public-update__footer {
  .container {
    margin-top: 145px;
    margin-bottom: 0;
  }
}
