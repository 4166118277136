.hamburger {
    @include flex-align-center;
    transition-duration: $speed-xfast;
    transition-property: opacity, filter;
    transition-timing-function: linear;

    .svg {
        position: relative;
    }

    .svg__path {
        fill: none;
        position: relative;
        stroke: $color-black;
        stroke-linecap: square;
        stroke-width: 1px;
        transform-origin: 50% 50%;
        transition-duration: $speed-fast;
        transition-property: stroke, transform, opacity;
    }

    &#{$state-active} {
        .svg__path {
            &:nth-child(1) {
                transform: rotate(45deg) translateY(5px);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-45deg) translateY(-5px);
            }
        }
    }
}
