.loadable {
    opacity: 0;
    transition: opacity $speed-normal;

    &#{$state-loaded} {
        opacity: 1;
    }
}

.no-js {
    .loadable {
        opacity: 1;
        transition: none;
    }
}
