.button {
    @include font-family(sharp-grotesk-19);
    @include font-size(small);
    @include flex-align-center;
    background-color: $color-red;
    border: 1px solid transparent;
    color: $color-peach;
    display: inline-flex;
    min-height: $form-button-size;
    padding: ($grid-gutter-small + 1px) $form-button-padding ($grid-gutter-small - 1px);
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: background-color $speed-fast, color $speed-fast, border-color $speed-fast;

    .svg__mask {
        fill: $color-red;
        transition: fill $speed-fast;
    }

    &:hover {
        background-color: $color-dark-red;
        border-color: $color-dark-red;

        .svg__mask {
            fill: $color-dark-red;
        }
    }
}

.button--big {
    max-width: rem(160px);
    min-height: rem(54px);
    width: 100%;
}

.button--peach {
    background-color: $color-peach;
    color: $color-black;

    .svg__mask {
        fill: $color-peach;
    }

    &:hover {
        background-color: $color-lola;
        border-color: $color-lola;

        .svg__mask {
            fill: $color-lola;
        }
    }
}

.button--bazaar {
    background-color: $color-bazaar;
    color: $color-peach;

    .svg__mask {
        fill: $color-bazaar;
    }

    &:hover {
        background-color: darken($color-bazaar, 5%);
        border-color: darken($color-bazaar, 5%);

        .svg__mask {
            fill: darken($color-bazaar, 5%);
        }
    }
}

.button--lola {
    background-color: $color-lola;
    color: $color-black;

    .svg__mask {
        fill: $color-lola;
    }

    &:hover {
        background-color: darken($color-lola, 5%);
        border-color: darken($color-lola, 5%);

        .svg__mask {
            fill: darken($color-lola, 5%);
        }
    }
}

.button--yellow {
    background-color: $color-yellow;
    color: $color-black;

    .svg__mask {
        fill: $color-yellow;
    }

    &:hover {
        background-color: $color-dark-yellow;
        border-color: $color-dark-yellow;

        .svg__mask {
            fill: $color-dark-yellow;
        }
    }
}

.button--outline {
    background-color: transparent;
    border-color: $color-red;
    color: $color-red;

    .link:hover &,
    &:hover {
        background-color: $color-dark-red;
        color: $color-peach;
    }

    &.button--yellow {
        border-color: $color-yellow;
        color: $color-yellow;

        &:hover {
            background-color: $color-dark-yellow;
            color: $color-black;
        }
    }
}

.button--small {
    @include text-button-small;
}

.button--form {
    border: $form-border $color-red;
    height: $form-input-height;
    white-space: nowrap;
}

.button--icon {
    border: 0;
    height: $form-button-size;
    margin-left: $form-button-size;
    padding-left: rem(1px);
    transition-timing-function: linear;
    white-space: nowrap;

    .svg__mask {
        transition-timing-function: linear;
    }
}

.button--more {
    &::after {
        // two non-breaking spaces + right-facing arrow
        content: '\00A0\00A0\2192';
        display: inline;
        font-weight: weight(regular);
    }
}

.button--fill {
    width: 100%;
}

.button__svg {
    display: block;
    height: 100%;
    position: absolute;
    right: 100%;
    top: 0;
    transform: translateX(rem(1px));
    width: $form-button-size;
}

@include breakpoint(below-medium) {
    .button--print {
        display: none;
    }
}
