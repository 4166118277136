.h1 {
    @include h1;
}

.h2 {
    @include h2;
}

.h3 {
    @include h3;
}

.h4 {
    @include h4;
}

.h4-serif {
    @include h4-serif;
}

.h5 {
    @include h5;
}

.h5-serif {
    @include h5-serif;
}

.h6 {
    @include h6;
}

.h7 {
    @include h7;
}

.h7-serif {
    @include h7-serif;
}
