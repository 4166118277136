.flex-top {
    align-items: flex-start;
}

.flex-center {
    align-items: center;
}

.flex-center-content {
    align-content: center;
}

.flex-start {
    align-items: flex-start;
}

.flex-middle {
    justify-content: center;
}

.flex-end {
    justify-content: flex-end;
}

.flex-column {
    flex-direction: column;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-grow {
    flex-grow: 1;
}

.flex-shrink {
    flex-shrink: 1;
}

.flex-wrap {
    flex-flow: wrap;
}
