.pager-nav {
    @include clearfix;
    border-bottom: 1px solid $color-lola;
    border-top: 1px solid $color-lola;
    padding-bottom: $grid-gutter;
    padding-top: $grid-gutter;
}

.pager-nav__link {
    @include h7;
    color: $color-red;
}

.pager-nav__link--previous {
    float: left;

    &::before {
        // left-facing arrow
        content: '\2190';
        display: inline;
        font-weight: weight(regular);
    }
}

.pager-nav__link--next {
    float: right;

    &::after {
        // right-facing arrow
        content: '\2192';
        display: inline;
        font-weight: weight(regular);
    }
}

@include breakpoint(medium) {
    .pager-nav__link {
        @include h6;
    }
}
