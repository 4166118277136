.informational {
    margin-bottom: $grid-gutter-xxlarge;
    margin-top: $grid-gutter;
    position: relative;
}

.informational__header {
    background-color: $color-peach;
    margin-bottom: $grid-gutter;
}

.informational__body {
    margin-top: $grid-gutter-large;
}

.informational__logo-grid {
    .container {
        margin-left: 0;
        margin-right: 0;
    }

    &.section {
        padding-bottom: 0;
    }
}

.informational__ctas {
    margin-top: $grid-gutter-large;

    &:first-child {
        margin-top: 0;
    }
}

.informational__footer {
    margin-top: $grid-gutter-xlarge;

    &:first-child {
        margin-top: 0;
    }
}

@include breakpoint(below-medium) {
    .informational__ctas {
        display: none;
    }
}

@include breakpoint(medium) {
    .informational {
        margin-top: $grid-gutter-medium;
    }

    .informational__aside {
        left: 0;
        padding-right: $grid-gutter * 2;
        position: absolute;
        top: 0;
        width: column(5);
        z-index: 2;  // overlap above stream
    }

    .informational__header {
        margin-left: column(4);
        padding: 0 column(1) $grid-gutter $grid-gutter;
        position: relative;
        top: -$grid-gutter-xsmall;  // align with aside
        width: column(8);
        z-index: 3;  // overlap above aside
    }

    .informational__body {
        margin-top: 0;
        padding-left: column(5);
        padding-right: column(1);
    }

    .informational__footer {
        margin-left: column(5);
        width: column(6);
    }
}
