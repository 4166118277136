.headshot__top {
    position: relative;
}

.headshot__bottom {
    margin-top: 6px;
}

.headshot__name {
    bottom: -7px;
    left: 0;
    position: absolute;
}

.headshot__name--role {
    position: static;
}

.headshot__name-text {
    @include h6;
    @include highlight-small($color-peach);
    padding-left: 0;
    padding-top: 4px;
    line-height: 1;
}

.headshot__title {
    @include text-body-small;
    font-style: italic;
    margin-top: $grid-gutter-xsmall;
}
