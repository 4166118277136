.rule {
    border-top: 3px solid $color-lola;
    margin-bottom: $grid-gutter-medium;
}

.rule--thin {
    border-top-width: 1px;
}

.rule--no-gutter {
    margin-bottom: 0;
}
