.nav--sub {
    .nav__link {
        color: $color-red;

        &:hover {
            color: $color-dark-red;
        }

        &#{$state-active} {
            color: $color-black;
        }
    }
}

@include breakpoint(below-medium) {
    .nav--sub {
        border-bottom: 1px solid $color-lola;
        border-top: 1px solid $color-lola;
        margin-bottom: $grid-gutter;
        margin-top: $grid-gutter;
        padding-bottom: $grid-gutter-small;
        padding-top: $grid-gutter-small;

        .nav__list {
            @include list-single-line;
            @include list-horizontal-ruled($color-lola);
        }

        .nav__item {
            @include h7;
        }
    }
}

@include breakpoint(medium) {
    .nav--sub {
        margin-bottom: $grid-gutter-large;
        margin-top: $grid-gutter-medium;

        .nav__item {
            @include h6;
            margin-top: $grid-gutter-small;

            &:first-child {
                margin-top: 0;
            }
        }

        &:first-child {
            margin-top: 0;
        }
    }
}
