.header {
    @include z-index(header);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .nav--series {
        transition: opacity $speed-fast;
    }

    .donation-bar-link {
        .donation-bar {
            opacity: 0.85;
            padding: 5px 0;
            text-align: center;
            transition: opacity 0.3s;
            font-size: 16px;

            span {
                font-weight: 700;
                text-decoration: underline;
            }
        }

        &:hover {
            .donation-bar {
                opacity: 1;

                span {
                    color: #e62b12;
                    text-decoration: none;
                    transition: all 0.3s;
                }
            }
        }
    }
}

.header__container,
.header__title,
.header__title-link {
    height: 100%;

    .logo-text {
        @include font-family(noe-display);
        @include font-weight(semibold);
        font-size: 42px;
        line-height: 0.85;
        color: #000;
    }
}

.header__top {
    @include flex-align-center;
    flex: 1 1 auto;
    height: $header-height;
}

.header__middle,
.header__bottom {
    display: none;
}

.header__title,
.header__title-link {
    @include flex-align-center;
}

.header__title {
    padding: $grid-gutter $grid-gutter-medium;
    text-align: center;
}

.header__logo-svg {
    .svg__path {
        transition-duration: $speed-xfast;
    }
}

.header__nav {
    @include font-family(sharp-grotesk-19);
}

.header__hamburger {
    height: $header-nav-height-large;
    position: absolute;
    right: 0;
    top: $grid-gutter;
    width: $header-nav-height-large;
}

@include breakpoint(below-large) {
    .header {
        @include font-size(large);
        color: $color-peach;
        height: $header-height;

        &#{$state-active} {
            .header__container {
                background-color: $color-red;
                height: 100vh;
                left: 0;
                margin-left: 0;
                margin-right: 0;
                overflow-y: auto;
                padding-bottom: $grid-gutter;
                position: fixed;
                top: 0;
                transition-duration: $speed-fast;
                width: 100%;
            }

            .header__logo-svg {
                .svg__path {
                    fill: $color-peach;
                    transition-duration: $speed-fast;
                }
            }

            .header__hamburger {
                right: $grid-gutter;
            }

            .header__hamburger-svg {
                .svg__path {
                    stroke: $color-peach;
                    transition-duration: $speed-fast;
                }
            }

            .header__middle,
            .header__bottom {
                display: block;
            }
        }
    }

    .header__container {
        transition-duration: $speed-xfast;
        transition-property: background-color, height;
    }

    .header__top {
        position: relative;
    }

    .header__nav {
        margin-top: $grid-gutter-medium;
        padding-top: $grid-gutter-medium;
        position: relative;
        text-align: center;

        // fake top border
        &::before {
            background-color: rgba($color-peach, .33);
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            width: 90px;
        }

        .nav__item {
            margin-top: $grid-gutter-medium;

            &:first-child {
                margin-top: 0;
            }
        }

        &.nav--top-level {
            // compensate for header__title's bottom gutter
            margin-top: $grid-gutter-medium - $grid-gutter;
        }

        &.nav--series {
            margin-bottom: $grid-gutter-medium;
            padding-bottom: $grid-gutter-medium;

            // fake bottom border
            &::after {
                background-color: rgba($color-peach, .33);
                bottom: 0;
                content: '';
                display: block;
                height: 1px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: 90px;
            }
        }
    }
}

@include breakpoint(medium) {
    .header,
    .header__top {
        height: $header-height-large;
    }

    .header__hamburger {
        top: $grid-gutter-medium;
    }
}

@include breakpoint(large) {
    .header {
        @include font-size(small);

        &#{$state-search-open} {
            .nav--series {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .header__container,
    .header__top,
    .header__middle,
    .header__bottom {
        @include flex-align-center;
        flex-wrap: nowrap;
    }

    .header__top {
        flex: 1 1 auto;
        order: 2;
    }

    .header__middle,
    .header__bottom {
        flex: 0 0 column(4);
        height: $header-nav-height-large;
    }

    .header__middle {
        justify-content: flex-start;
        order: 1;
    }

    .header__bottom {
        justify-content: flex-end;
        order: 3;
        position: relative;
    }

    .header__nav {
        flex: 0 1 auto;

        .nav__list {
            display: flex;
            flex-wrap: nowrap;
        }

        .nav__item {
            flex: 0 0 auto;
            margin-left: $grid-gutter;

            &:first-child {
                margin-left: 0;
            }
        }

        .nav__link {
            &#{$state-active} {
                color: $color-dark-red;
                pointer-events: auto;
            }
        }
    }

    .header__hamburger {
        display: none;
    }
}

@include breakpoint(xlarge) {
    .header {
        font-size: rem(14px);
    }

    .header__nav {
        .nav__item {
            margin-left: $grid-gutter-medium;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

@include breakpoint(xxlarge) {
    .header__nav {
        .nav__item {
            margin-left: $grid-gutter-large;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

#donate-modal-overlay {
    position:fixed;
    display:table;
    height:100%;
    width:100%;
    top:0;
    left:0;
    transform:scale(0);
    z-index:10000;

    .modal-background {
        display:table-cell;
        background:rgba(0,0,0,.8);
        text-align:center;
        vertical-align:middle;
      }

    &.show-modal {
        transform:scaleY(.01) scaleX(0);
        animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        .modal-background {
            .donate-modal {
                transform:scale(0);
                animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            }
        }
        &.out {
            transform:scale(1);
            animation:unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            .modal-background {
                .donate-modal {
                        animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
                }
            }
        }
    }

    .donate-modal {
        .promo-image {
            max-width: 90%;
            @media (min-width: 768px) {
                max-width: 80%;
            }
            @media (min-width: 992px) {
                max-width: 70%;
            }
        }

        &-close {
            position: absolute;
            right: 15px;
            top: 25px;
            font-size: 20px;
            color: #fff;
            z-index: 10100;
            &:hover {
                cursor: pointer;
            }

            @media (min-width: 768px) {
                right: 20px;
                top: 20px;
            }
        }
        &-body {
            max-width: 1000px;
            width: 100%;
            margin: 0 auto;
        }

        &-body{
            border-top: 10px solid #ffd400;
            background: #000;

            &-header {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 10px;

                @media (min-width: 768px) {
                    flex-direction: row;
                    padding: 5px 15px;
                }

                .logos {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    margin-bottom: 10px;

                    @media (min-width: 768px) {
                        justify-content: normal;
                        margin-bottom: 0;
                    }

                    img, svg {
                        height: 50px;
                        width: auto;
                    }

                    svg {
                        height: 30px;
                        path {
                            fill: #fff;
                        }
                    }

                    img {
                        &.shorts {
                            height: 40px;
                        }
                    }
                }

                .text {
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    align-items: end;

                    @media (min-width: 768px) {
                        align-items: normal;
                    }

                    .hash-tag {
                        color: #ffd400;
                        font-family: "Sharp Grotesk 19", sans-serif;
                    }

                    .season {
                        color: #fff;
                        font-family: "Sharp Grotesk 20", sans-serif;
                        width: 70%;
                        @media (min-width: 768px) {
                            width: 100%;
                        }
                    }
                }
            }

            .image-holder {
                padding: 0 10px 10px;
                background: rgb(201,60,40);
                background: -moz-linear-gradient(0deg, rgba(201,60,40,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%);
                background: -webkit-linear-gradient(0deg, rgba(201,60,40,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%);
                background: linear-gradient(0deg, rgba(201,60,40,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c93c28",endColorstr="#000000",GradientType=1);

                @media (min-width: 768px) {
                    padding: 0 15px 15px;
                }

                .gradient {
                    max-height: 180px;
                    overflow: hidden;

                    @media (min-width: 768px) {
                        max-height: 300px;
                    }
                }
            }

            &-footer {
                display: flex;
                flex-direction: column;

                img {
                    max-width: 60%;
                    width: 100%;
                    margin: 25px auto;
                }
                a {
                    background: #ffd400;
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    font-family: Noe Display,serif;
                    font-style: italic;
                    transition: all 0.3s;
                    width: 200px;
                    height: 40px;
                    border-radius: 8px;
                    margin: 0 auto 30px;
                    font-size: 27px;
                    font-weight: 500;

                    &:hover {
                        background: rgba(#ffd400, 0.85);
                    }

                    @media (min-width: 768px) {
                        width: 270px;
                        height: 55px;
                        border-radius: 12px;
                        margin: 0 auto 35px;
                        font-size: 35px;
                    }
                }
            }
        }
    }
}


@keyframes unfoldIn {
  0% {
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform:scaleY(1) scaleX(1);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(.005) scaleX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}
