// sass-lint:disable class-name-format no-important

.fb-customerchat-toggle {
    display: none;
}

.fb-customerchat {
    iframe {
        bottom: $grid-gutter-small !important;
        right: $grid-gutter-small !important;
    }
}

.fb_dialog {
    display: none !important;
}
