.packery {
    overflow: hidden;
    padding-left: $packery-gutter-xsmall;

    .load-more {
        margin-right: $packery-gutter-xsmall;
    }
}

.packery__items {
    box-sizing: content-box;
    margin-bottom: $packery-gutter-xsmall;
    position: relative;
    transition: height $speed-normal;
}

.packery__width-sizer,
.packery__item {
    box-sizing: content-box;
    width: column-without-gutter(12, $packery-gutter-xsmall);
}

.packery__item {
    float: left;
}

.packery__gutter-sizer {
    width: $packery-gutter-xsmall;
}

@include breakpoint(small) {
    .packery {
        padding-left: $packery-gutter-small;

        .load-more {
            margin-right: $packery-gutter-small;
        }
    }

    .packery__items {
        margin-bottom: $packery-gutter-small;
    }

    .packery__width-sizer,
    .packery__item {
        width: column-without-gutter(6, $packery-gutter-small);
    }

    .packery__item--streaming,
    .packery__item--featured {
        width: column-without-gutter(12, $packery-gutter-small);
    }

    .packery__gutter-sizer {
        width: $packery-gutter-small;
    }

    .packery__item__meta {
        display: block;
    }
}

@include breakpoint(medium) {
    .packery {
        padding-left: $packery-gutter-medium;

        .load-more {
            margin-right: $packery-gutter-medium;
        }
    }

    .packery__items {
        margin-bottom: $packery-gutter-medium;
    }

    .packery__width-sizer,
    .packery__item {
        width: column-without-gutter(6, $packery-gutter-medium);
    }

    .packery__item--streaming,
    .packery__item--featured {
        width: column-without-gutter(12, $packery-gutter-medium);
    }

    .packery__gutter-sizer {
        width: $packery-gutter-medium;
    }
}

@include breakpoint(large) {
    .packery {
        padding-left: $packery-gutter-large;

        .load-more {
            margin-right: $packery-gutter-large;
        }
    }

    .packery__items {
        margin-bottom: $packery-gutter-large;
    }

    .packery__width-sizer,
    .packery__item {
        width: column-without-gutter(3, $packery-gutter-large);
    }

    .packery__item--streaming {
        width: column-without-gutter(6, $packery-gutter-large);

        .thumb__top {
            @include aspect-ratio-with-gutter(16, 9, $packery-gutter-large);
        }
    }

    .packery__item--featured {
        width: column-without-gutter(12, $packery-gutter-large);
    }

    .packery__gutter-sizer {
        width: $packery-gutter-large;
    }
}

@include breakpoint(xxlarge) {
    .packery {
        padding-left: $packery-gutter-xlarge;

        .load-more {
            margin-right: $packery-gutter-xlarge;
        }
    }

    .packery__items {
        margin-bottom: $packery-gutter-xlarge;
    }

    .packery__width-sizer,
    .packery__item {
        width: column-without-gutter(3, $packery-gutter-xlarge);
    }

    .packery__item--streaming {
        width: column-without-gutter(6, $packery-gutter-xlarge);

        .thumb__top {
            @include aspect-ratio-with-gutter(16, 9, $packery-gutter-xlarge);
        }
    }

    .packery__item--featured {
        width: column-without-gutter(12, $packery-gutter-xlarge);
    }

    .packery__gutter-sizer {
        width: $packery-gutter-xlarge;
    }
}
