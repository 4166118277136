.social-banner {
    padding-bottom: $grid-gutter-large;
    padding-top: $grid-gutter-large;
}

.social-banner__title {
    margin-bottom: $grid-gutter-medium;
    text-align: center;

    &:only-child {
        margin-bottom: 0;
    }
}

.social-banner__link {
    @include text-eyebrow;
    color: $color-bazaar;
    margin-left: $grid-gutter;

    &:hover {
        .social-banner__icon {
            color: $color-dark-red;
        }
    }

    &:first-child {
        margin-left: 0;
    }
}

.social-banner__icon {
    font-size: rem(40px);
    line-height: 1;
    transition: color $speed-fast;
}

@include breakpoint(below-small) {
    .social-banner__links {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .social-banner__link {
        flex: 0 0 auto;
        margin-bottom: $grid-gutter-medium;
        margin-left: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .social-banner__icon {
        display: block;
        margin-bottom: $grid-gutter-xxsmall;
    }
}

@include breakpoint(small) {
    .social-banner__row {
        @include row;
        flex-direction: column;
        justify-content: center;
    }

    .social-banner__title,
    .social-banner__links {
        @include column;
    }

    .social-banner__links {
        display: flex;
        justify-content: center;
    }

    .social-banner__icon {
        font-size: rem(32px);
        padding-right: $grid-gutter-small;
    }
}

@include breakpoint(large) {
    .social-banner {
        padding-bottom: $grid-gutter-xlarge;
        padding-top: $grid-gutter-xlarge;
    }

    .social-banner__row {
        flex-direction: row;
    }

    .social-banner__title {
        align-items: center;
        display: flex;
        margin-bottom: 0;
        text-align: left;
        width: column(4);

        &:only-child {
            justify-content: center;
        }
    }

    .social-banner__links {
        @include column;
        align-content: center;
        display: flex;
        justify-content: flex-end;
        width: column(8);
    }

    .social-banner__link {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        margin-left: 0;
        width: column(3);
    }
}

@include breakpoint(xlarge) {
    .social-banner__icon {
        padding-right: $grid-gutter;
    }
}
