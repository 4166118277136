// sass-lint:disable no-important

@media print {
    // general
    div.stream__block {
        code {
            display: block;
            page-break-after: always;
            page-break-inside: avoid;
            visibility: hidden;
            height: 0;
            position: relative;
        }
    }

    .hide-for-print {
        display: none !important;
    }

    .nav {
        display: none;
    }

    .header-search {
        display: none;
    }

    .hamburger {
        display: none;
    }

    .secondary-nav {
        display: none;
    }

    footer {
        display: none;
    }

    // resource/generic pages
    .nav--sub {
        display: none;
    }

    .button--print {
        display: none;
    }

    .stream__block .video {
        display: none;
    }

    .informational.print-True {
        .stream.stream--informational {
            display: none;
        }
        .informational__print_block {
            display: block;

            .stream.stream--informational {
                display: block;
            }
        }
    }

}
