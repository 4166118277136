.flickity-enabled {
    position: relative;

    &:focus {
        outline: none;
    }

    &.is-draggable {
        user-select: none;
    }
}

.flickity-viewport {
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: height $speed-fast;
}

.flickity-slider {
    height: 100%;
    position: absolute;
    width: 100%;
}

.flickity-prev-next-button {
    height: rem(36px);
    position: absolute;
    top: 50%;
    width: rem(36px);

    &.previous {
        left: $grid-gutter;
        transform: translateY(-50%) rotate(180deg);
    }

    &.next {
        right: 0;
        transform: translateY(-50%) rotate(-180deg);
    }

    &:disabled {
        display: none;
    }
}

.flickity-button-icon {
    fill: none;
    stroke: $color-red;
}

@include breakpoint(below-medium) {
    .flickity-prev-next-button {
        &.previous {
            display: none;
        }
    }
}

@include breakpoint(medium) {
    .flickity-prev-next-button {
        &.previous {
            left: auto;
            margin-right: $grid-gutter-small;
            right: 100%;
        }

        &.next {
            left: 100%;
            margin-left: $grid-gutter + $grid-gutter-small;
            right: auto;
        }
    }
}
