$checkmark-width: 22px;
$checkmark-height: 20px;

.checkbox {
    @include font-family(sharp-grotesk-19);
    cursor: pointer;
    display: block;
    font-size: size(small);
    line-height: $checkmark-height;
    padding-left: $checkmark-width + $grid-gutter-small;
    position: relative;
    user-select: none;
    white-space: nowrap;
}

.checkbox__input {
    cursor: pointer;
    opacity: 0;
    position: absolute;

    &:checked ~ .checkbox__checkmark {
        @include background-image('checkbox-checked.svg', false);
    }
}

.checkbox__checkmark {
    @include background-image('checkbox.svg');
    height: $checkmark-height;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-image $speed-fast;
    width: $checkmark-width;
}
