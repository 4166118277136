.public-update__nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  background-color: $color-ebb;

  .spacer {
      height: 24px;
  }

  .nav{
    &__title{
      flex: 1 0 auto;
      background-color: $color-peach;
      padding: .5rem 1rem .4rem;

      &-text{
        @include h4;
        color: $color-bazaar;
      }
    }
    &__icon{
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &-btn{
        border: none;
        background-color: transparent;
      }
      &.icon--refresh:not(:active) svg{
        transform: rotate(360deg);
        transition: transform 500ms ease-in-out;
      }
      &-svg{
        path{
          fill: $color-dark-red;
        }
      }
    }
  }
}
