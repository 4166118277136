$help-size: rem(146px);

.footer {
    background-color: $color-black;
    color: $color-peach;
    margin-top: $help-size;
    padding-bottom: $grid-gutter-medium;
}

.footer__container {
    padding-top: $grid-gutter + $grid-gutter-medium;
}

.footer__help {
    height: $help-size;
    position: absolute;
    right: 50%;
    top: 0;
    transform: translate(50%, -50%);
    width: $help-size;
}

.footer__tier {
    padding-top: $grid-gutter-medium;
}

.footer__nav {
    @include font-family(sharp-grotesk-19);
    @include font-size(small);
    border-top: 1px solid rgba($color-peach, .4);
    padding-top: $grid-gutter-xsmall;

    .nav__item {
        margin-top: $grid-gutter-xsmall;
    }
}

@include breakpoint(medium) {
    .footer__container {
        padding-top: $grid-gutter;
    }

    .footer__help {
        right: $grid-gutter-medium;
        transform: translate(0, -50%);
    }
}

@include breakpoint(large) {
    .footer__container {
        padding-top: 0;
    }

    .footer__tier {
        padding-top: $grid-gutter-xlarge;
    }
}


.footer_logos {
    div {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 50px;
            width: auto;
        }
    }
}
