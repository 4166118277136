.input {
    @include font-family(sharp-grotesk-19);
    @include font-size(small);
    border: $form-border $color-red;
    height: $form-input-height;
    min-width: 0;
    padding-left: $form-input-padding;
    padding-right: $form-input-padding;
    width: 100%;

    &::placeholder {
        color: $color-red;
        opacity: 1;
    }
}
