$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
  @if $separator == "comma" or $separator == "space" {
    @return append($list, null, $separator);
  } 
  
  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);
  
  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, $value / $rem-baseline * 1rem, $separator);
    } @else if type-of($value) == "list" {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

// fade in

@keyframes fade-in {
    100% {
        opacity: 1;
    }
}

@mixin fade-in {
    animation-duration: .33s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: fade-in;
    animation-timing-function: ease-in-out;
}

@keyframes flip-in-x {
    from {
        animation-timing-function: ease-in;
        transform: rotate3d(1, 0, 0, 90deg);
    }

    to {
        transform: rotate3d(1, 0, 0, 0deg);
    }
}

@mixin flip-in-x {
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: flip-in-x;
    backface-visibility: visible;
}

$color-black:       rgb(0, 0, 0);
$color-peach:       rgb(247, 242, 243);
$color-ebb:         rgb(237, 228, 229);
$color-lola:        rgb(230, 221, 223);
$color-pink:        rgb(188, 166, 170);
$color-bazaar:      rgb(156, 131, 135);
$color-red:         rgb(255, 58, 32);
$color-dark-red:    rgb(230, 43, 18);
$color-yellow:      rgb(249, 231, 158);
$color-dark-yellow: rgb(237, 218, 140);

$form-border:         1px solid;

$form-input-height:   rem(54px);
$form-input-padding:  rem(22px);

$form-button-size:    rem(40px);
$form-button-padding: rem(32px);

// breakpoints

$breakpoints: (
    xxsmall: 419px,
    xsmall:  420px,
    small:   640px,
    medium:  840px,
    large:   1024px,
    xlarge:  1260px,
    xxlarge: 1430px
);

@function breakpoint ($name) {
    @return map-get($breakpoints, $name);
}

$breakpoint-map: (
    xxsmall: (
        max: breakpoint(xxsmall)
    ),
    xsmall: (
        min: breakpoint(xsmall)
    ),
    xsmall-only: (
        min: breakpoint(xsmall),
        max: breakpoint(small) - 1
    ),
    below-small: (
        max: breakpoint(small) - 1
    ),
    small: (
        min: breakpoint(small)
    ),
    small-only: (
        min: breakpoint(small),
        max: breakpoint(medium) - 1
    ),
    below-medium: (
        max: breakpoint(medium) - 1
    ),
    medium: (
        min: breakpoint(medium)
    ),
    medium-only: (
        min: breakpoint(medium),
        max: breakpoint(large) - 1
    ),
    below-large: (
        max: breakpoint(large) - 1
    ),
    large: (
        min: breakpoint(large)
    ),
    large-only: (
        min: breakpoint(large),
        max: breakpoint(xlarge) - 1
    ),
    xlarge: (
        min: breakpoint(xlarge)
    ),
    xlarge-only: (
        min: breakpoint(xlarge),
        max: breakpoint(xxlarge) - 1
    ),
    xxlarge: (
        min: breakpoint(xxlarge)
    )
);

@mixin breakpoint($name, $type: 'all', $min-res: null) {
    $breakpoint: map-get($breakpoint-map, $name);
    $query: '#{$type}';

    @if map-has-key($breakpoint, min) {
        $query: append($query, ' and (min-width: #{map-get($breakpoint, min)})');
    }

    @if map-has-key($breakpoint, max) {
        $query: append($query, ' and (max-width: #{map-get($breakpoint, max)})');
    }

    @if ($min-res) {
        $query: append($query, ' and (min-resolution: #{$min-res}dpi)');
    }

    @media #{$query} {
        @content;
    }
}


// screen resolutions (dpi)

$resolutions: (
    retina: 192
);

@function resolution ($name) {
    @return map-get($resolutions, $name);
}


// z-index

$z-layers: (
    bottom,
    body,
    content,
    filters,
    header,
    modal,
    top
);

@mixin z-index ($layer) {
    z-index: #{index($z-layers, $layer)};
}


// grid

$grid-max-width: 1280px;

$grid-columns: 12;

$grid-gutter:          20px;
$grid-gutter-xxsmall:  3px;
$grid-gutter-xsmall:   5px;
$grid-gutter-small:    10px;
$grid-gutter-medium:   36px;
$grid-gutter-large:    52px;
$grid-gutter-xlarge:   64px;
$grid-gutter-xxlarge:  75px;
$grid-gutter-xxxlarge: 128px;

@mixin container {
    margin-left: $grid-gutter;
    margin-right: $grid-gutter;
    position: relative;

    @include breakpoint(medium) {
        margin-left: $grid-gutter-xxlarge;
        margin-right: $grid-gutter-xxlarge;
    }

    @include breakpoint(xxlarge) {
        margin-left: $grid-gutter-xxxlarge;
        margin-right: $grid-gutter-xxxlarge;
    }
}

@mixin padding-container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
    position: relative;

    @include breakpoint(medium) {
        padding-left: $grid-gutter-xxlarge;
        padding-right: $grid-gutter-xxlarge;
    }

    @include breakpoint(xxlarge) {
        padding-left: $grid-gutter-xxxlarge;
        padding-right: $grid-gutter-xxxlarge;
    }
}

@function column ($count) {
    @return percentage($count / $grid-columns);
}

@function column-without-gutter ($count, $gutter) {
    $column-width: percentage($count / $grid-columns);

    @return calc(#{$column-width} - #{$gutter});
}

@mixin row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter;
    width: calc(100% + #{$grid-gutter});
}

@mixin row-ruled ($color) {
    > * {
        position: relative;

        + * {
            padding-top: $grid-gutter;
        }

        // fake border to compensate for column gutter (padding)
        &::after {
            background-color: $color;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;
        }

        &.column {
            &::after {
                left: $grid-gutter;
            }
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

@mixin row-ruled-tight {
    > * {
        + * {
            padding-top: 0;
        }
    }
}

@mixin row-no-rule {
    @include row-ruled-tight;

    > * {
        // hide fake border
        &::after {
            display: none;
        }
    }
}

@mixin column {
    flex: 0 0 auto;
    padding-left: $grid-gutter;
}

@mixin column-ruled ($color) {
    border-right: 1px solid $color;

    &:last-child {
        border-right: 0;
    }
}

@mixin column-no-rule {
    border-right: 0;
}


// lists

@mixin list-single-line {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    > * {
        flex: 0 1 auto;
        margin-bottom: $grid-gutter-xxsmall;
        margin-right: $grid-gutter;
        margin-top: $grid-gutter-xxsmall;

        &:last-child {
            margin-right: 0;
        }
    }
}

@mixin list-horizontal-ruled ($color) {
    > * {
        position: relative;

        // rule
        &::after {
            background: $color;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            right: -$grid-gutter / 2;
            top: 0;
            width: 1px;
        }

        &:last-child {
            // rule
            &::after {
                display: none;
            }
        }
    }
}


// aspect ratio

@mixin aspect-ratio($width, $height) {
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: ($height / $width) * 100%;
        width: 100%;
    }
}

@mixin aspect-ratio-with-gutter($width, $height, $gutter) {
    $aspect-ratio: ($height / $width) * 100%;

    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: calc(#{$aspect-ratio} + #{$gutter});
        width: 100%;
    }
}

// css columns

$css-columns: 6;

@mixin columns($count, $gutter: $grid-gutter) {
    column-count: $count;
    column-gap: $gutter;
}

@mixin no-columns {
    column-count: auto;
    column-gap: normal;
}


// global layout variables

$header-height: 100px;
$header-height-large: 160px;
$header-nav-height-large: 32px;

$packery-gutter-xsmall: 4%;
$packery-gutter-small: 2.5%;
$packery-gutter-medium: 2%;
$packery-gutter-large: 1.5%;
$packery-gutter-xlarge: 1%;

$speed-slow:   .8s;
$speed-medium: .5s;
$speed-normal: .33s;
$speed-fast:   .25s;
$speed-xfast:  .15s;

$state-active:      '.is-active';
$state-selected:    '.is-selected';
$state-filled:      '.is-filled';
$state-streaming:   '.is-streaming';
$state-playing:     '.is-playing';
$state-stuck:       '.is-stuck';
$state-search-open: '.is-search-open';
$state-modal-open:  '.is-modal-open';
$state-loading:     '.is-loading';
$state-loaded:      '.is-loaded';
$state-disabled:    '.is-disabled';
$state-setup:       '.is-setup';
$state-error:       '.is-error';

// sass-lint:disable no-color-keywords


// font sizes

$font-sizes: (
    xsmall:  (rem(10px), 1.3),
    small:   (rem(13px), 1.54),
    normal:  (rem(16px), 1.5),
    medium:  (rem(20px), 1.2),
    large:   (rem(24px), 1.1),
    xlarge:  (rem(36px), 1.02),
    xxlarge: (rem(48px), 1.2)
);

@function size ($name) {
    @return nth(map-get($font-sizes, $name), 1);
}

@function line-height ($name) {
    @return nth(map-get($font-sizes, $name), 2);
}

@mixin font-size ($size) {
    $spec: map-get($font-sizes, $size);
    font-size: nth($spec, 1);
    line-height: nth($spec, 2);
}


// font weights

$font-weights: (
    hairline: 100,
    thin:     200,
    light:    300,
    regular:  400,
    medium:   500,
    semibold: 600,
    bold:     700,
    black:    800
);

@function weight ($name) {
    @return map-get($font-weights, $name);
}

@mixin font-weight ($name) {
    font-weight: weight($name);
}


// letter spacing

$letter-spacings: (
    small:  -.1px,
    medium: 1px,
    large:  1.6px,
    xlarge: 3px
);

@function spacing ($name) {
    @return nth(map-get($letter-spacings, $name), 1);
}

@mixin letter-spacing ($name) {
    letter-spacing: spacing($name);
}


// font smoothing

@mixin font-smoothing-antialiased {
    // sass-lint:disable-block no-vendor-prefixes
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

@mixin font-smoothing-auto {
    // sass-lint:disable-block no-vendor-prefixes
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
}


// font families

$font-families: (
    sharp-grotesk-19: '"Sharp Grotesk 19", sans-serif',
    sharp-grotesk-20: '"Sharp Grotesk 20", sans-serif',
    noe-text:         '"Noe Text", serif',
    noe-display:      '"Noe Display", serif',
    social-icons:     '"SS Social"'
);

@function family ($name) {
    @return unquote(map-get($font-families, $name));
}

@mixin font-family ($name) {
    font-family: family($name);

    @if ($name == 'sharp-grotesk-19') {
        // sharp grotesk 19 always has these properties:
        letter-spacing: spacing(medium);
        text-transform: uppercase;
    } @else {
        // override above
        text-transform: none;
    }
}


// headers

@mixin h1 {
    @include font-family(sharp-grotesk-19);
    @include font-weight(semibold);
    @include font-size(xlarge);
    letter-spacing: 0;
}

@mixin h2 {
    @include font-family(noe-display);
    @include font-weight(medium);
    font-size: size(xlarge);
    line-height: 1.278;
}

@mixin h3 {
    @include font-family(sharp-grotesk-20);
    @include font-weight(semibold);
    font-size: rem(32px);
    line-height: 1.156;
}

@mixin h4 {
    @include font-family(sharp-grotesk-20);
    @include font-weight(semibold);
    @include font-size(large);
}

@mixin h4-serif {
    @include font-family(noe-display);
    @include font-weight(medium);
    @include font-size(large);
}

@mixin h5 {
    @include font-family(sharp-grotesk-20);
    @include font-weight(semibold);
    @include font-size(medium);
}

@mixin h5-serif {
    @include font-family(noe-display);
    @include font-weight(regular);
    font-size: rem(18px);
    line-height: 1.333;
}

@mixin h6 {
    @include font-family(sharp-grotesk-20);
    @include font-weight(semibold);
    @include font-size(normal);
}

@mixin h7 {  // 😨
    @include font-family(sharp-grotesk-20);
    @include font-weight(semibold);
    font-size: rem(14px);
    line-height: 1.429;
}

@mixin h7-serif {
    @include font-family(noe-display);
    @include font-weight(regular);
    font-size: rem(14px);
    line-height: 1.429;
}


// misc text styles

@mixin text-body {
    @include font-family(noe-text);
    @include font-weight(light);
    @include font-size(normal);
    @include letter-spacing(small);
}

@mixin text-body-small {
    @include font-family(noe-text);
    @include font-weight(regular);
    @include font-size(small);
    @include letter-spacing(small);
}

@mixin text-pullquote {
    @include font-family(noe-display);
    @include font-weight(regular);
    font-size: size(xlarge);
    font-style: italic;
    line-height: 1.222;
}

@mixin text-real-big {
    @include font-family(noe-display);
    @include font-weight(regular);
    font-size: rem(60px);
    line-height: 1;
}

@mixin text-film-title {
    @include font-family(sharp-grotesk-20);
    @include font-weight(semibold);
    font-size: rem(52px);
    line-height: 1.25;

    @include breakpoint(below-small) {
        font-size: rem(42px);
    }
}

@mixin text-meta {
    @include font-family(sharp-grotesk-19);
    @include font-weight(medium);
    @include font-size(xsmall);
}

@mixin text-caption {
    @include font-family(sharp-grotesk-19);
    @include font-weight(regular);
    @include letter-spacing(large);
    font-size: size(xsmall);
    line-height: 1.6;
}

@mixin text-filter {
    @include font-family(noe-display);
    @include font-weight(regular);
    @include font-size(large);
}

@mixin text-article-link {
    @include font-family(noe-display);
    @include font-weight(regular);
    @include font-size(medium);
}

@mixin text-secondary-nav {
    @include font-family(noe-display);
    @include font-weight(bold);
    font-size: rem(30px);
    line-height: 1.3;
}

@mixin text-dropdown {
    @include font-family(noe-display);
    @include font-weight(regular);
    @include font-size(medium);
}

@mixin text-load-more {
    @include font-family(noe-display);
    @include font-weight(bold);
    font-size: rem(48px);
    line-height: 1.271;
}

@mixin text-button {
    @include font-family(sharp-grotesk-19);
    @include font-weight(regular);
    @include letter-spacing(large);
    font-size: size(small);
    line-height: 1.231;
}

@mixin text-button-small {
    @include font-family(sharp-grotesk-19);
    @include font-weight(regular);
    font-size: rem(11px);
    line-height: 1.273;
}

@mixin text-eyebrow {
    @include font-family(sharp-grotesk-19);
    @include font-weight(regular);
    font-size: size(small);
    line-height: 1.308;
}

@mixin text-eyebrow-small {
    @include font-family(sharp-grotesk-19);
    @include font-weight(regular);
    @include font-size(xsmall);
}

@mixin text-emdash {
    &::after {
        @include font-family(noe-display);
        @include font-weight(medium);
        // non-breaking space + an em dash
        content: '\00A0\00A0\2014';
        display: inline;
    }
}


// highlighted text

@mixin highlight($color) {
    background: $color;
    box-decoration-break: clone;
    display: inline;
    padding: 7px 16px 6px;
}

@mixin highlight-small($color) {
    background: $color;
    box-decoration-break: clone;
    display: inline;
    padding: 3px 10px;
}


// font face

@mixin font-face($name, $path, $weight: weight(regular), $style: normal, $exts: woff, $base-dir: '../fonts/') {
    $full-path: $base-dir + $path;
    $src: null;

    @each $ext in $exts {
        $src: append($src, url(quote($full-path + '.' + $ext)) format(quote($ext)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

@mixin background-image($filename, $set-all-properties: true, $position: '50% 50%', $size: 'auto auto', $repeat: 'no-repeat', $base-dir: '../images/') {
    background-image: url($base-dir + $filename);

    @if ($set-all-properties == true) {
        background-position: unquote($position);
        background-repeat: unquote($repeat);
        background-size: unquote($size);
    }
}

@mixin clearfix {
    &::before,
    &::after {
        content: '';
        display: table;
    }

    &::after {
        clear: both;
    }
}

@mixin flex-align-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

@mixin css-columns ($count, $gutter: null) {
    column-count: $count;

    @if ($gutter) {
        column-gap: $gutter;
    }

    > * {
        break-inside: avoid;
        page-break-inside: avoid;

        @if ($gutter) {
            margin-bottom: $gutter;
        }
    }
}

@mixin hide-visually {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin font-smoothing-antialiased {
    // sass-lint:disable-block no-vendor-prefixes
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

@mixin font-smoothing-auto {
    // sass-lint:disable-block no-vendor-prefixes
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
}

@import 'styles/base/reset';
@import 'styles/base/rem-baseline';
@import 'styles/base/display';
@import 'styles/base/position';
@import 'styles/base/flex';
@import 'styles/base/font-face';
@import 'styles/base/font-family';
@import 'styles/base/font-size';
@import 'styles/base/font-weight';
@import 'styles/base/font-styles';
@import 'styles/base/headings';
@import 'styles/base/text-align';
@import 'styles/base/background';
@import 'styles/base/color';
@import 'styles/base/screen-reader';
@import 'styles/base/selected-text';
@import 'styles/base/css-columns';
@import 'styles/base/hide';
@import 'styles/base/margin';

@import 'styles/module/micro/icon';
@import 'styles/module/micro/svg';
@import 'styles/module/micro/input';
@import 'styles/module/micro/checkbox';
@import 'styles/module/micro/button';
@import 'styles/module/micro/badge';
@import 'styles/module/micro/emdash';
@import 'styles/module/micro/link';
@import 'styles/module/micro/offset';
@import 'styles/module/micro/gutter';
@import 'styles/module/micro/rule';
@import 'styles/module/micro/ruled-title';
@import 'styles/module/micro/eyebrow';
@import 'styles/module/micro/list';
@import 'styles/module/micro/pullquote';
@import 'styles/module/micro/aside-box';
@import 'styles/module/micro/image';
@import 'styles/module/micro/video';
@import 'styles/module/micro/thumb';
@import 'styles/module/micro/topic';
@import 'styles/module/micro/headshot';
@import 'styles/module/micro/award';
@import 'styles/module/micro/author';
@import 'styles/module/micro/loadable';
@import 'styles/module/micro/load-more';
@import 'styles/module/micro/hamburger';
@import 'styles/module/micro/logo';
@import 'styles/module/micro/date';
@import 'styles/module/micro/filter';
@import 'styles/module/micro/fb-customerchat';
@import 'styles/module/micro/page-error';

@import 'styles/module/macro/page';
@import 'styles/module/macro/container';
@import 'styles/module/macro/row';
@import 'styles/module/macro/column';
@import 'styles/module/macro/header';
@import 'styles/module/macro/header-search';
@import 'styles/module/macro/nav';
@import 'styles/module/macro/secondary-nav';
@import 'styles/module/macro/pager-nav';
@import 'styles/module/macro/informational';
@import 'styles/module/macro/flickity';
@import 'styles/module/macro/hero';
@import 'styles/module/macro/intro';
@import 'styles/module/macro/section';
@import 'styles/module/macro/stream';
@import 'styles/module/macro/related';
@import 'styles/module/macro/stack';
@import 'styles/module/macro/media';
@import 'styles/module/macro/modal';
@import 'styles/module/macro/rich-text';
@import 'styles/module/macro/packery';
@import 'styles/module/macro/carousel';
@import 'styles/module/macro/thumb-grid';
@import 'styles/module/macro/faq';
@import 'styles/module/macro/link-list';
@import 'styles/module/macro/social-banner';
@import 'styles/module/macro/subscribe';
@import 'styles/module/macro/footer';
@import 'styles/module/macro/callout';
@import 'styles/module/macro/related_films';
@import 'styles/module/macro/photo-album';

@import 'styles/page/404';
@import 'styles/page/public-update';
@import 'styles/page/pressroom';
@import 'styles/page/impact';
@import 'styles/page/pswd-required';

@import 'styles/print';
