.aside-box {
    background-color: $color-pink;
    color: $color-peach;
    padding: rem(25px);

    .rich-text {
        @include text-body-small;
    }
}

.aside-box__title {
    @include text-caption;
    margin-bottom: $grid-gutter;
}
