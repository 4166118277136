.load-more__button {
    @include text-load-more;
    border-top: 1px solid $color-lola;
    display: block;
    line-height: 1;
    padding-top: $grid-gutter-medium;
    text-align: center;
    width: 100%;
}

@include breakpoint(medium) {
    .load-more__button {
        padding-top: $grid-gutter-xxlarge;
    }
}
