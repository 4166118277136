.row {
    @include row;
}

.row--nowrap {
    flex-wrap: nowrap;
}

.row--center {
    align-items: center;
}

.row--middle {
    justify-content: center;
}

.row--space-between {
    justify-content: space-between;
}

.row--ruled {
    @include row-ruled($color-lola);
}

.row--ruled-tight {
    @include row-ruled-tight;
}

.row--no-rule {
    @include row-no-rule;
}

.row--gutter {
    > * {
        padding-bottom: $grid-gutter;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.row--large-gutter {
    > * {
        padding-bottom: $grid-gutter-large;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.row--medium-gutter {
    > * {
        padding-bottom: $grid-gutter-medium;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.row--small-gutter {
    > * {
        padding-bottom: $grid-gutter-small;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.row--no-gutter {
    > * {
        padding-bottom: 0;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

@each $breakpoint in map-keys($breakpoints) {
    @include breakpoint($breakpoint) {
        .row--ruled-#{$breakpoint} {
            @include row-ruled($color-lola);
        }

        .row--no-rule-#{$breakpoint} {
            @include row-no-rule;
        }

        .row--gutter-#{$breakpoint} {
            > * {
                padding-bottom: $grid-gutter;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .row--large-gutter-#{$breakpoint} {
            > * {
                padding-bottom: $grid-gutter-large;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .row--medium-gutter-#{$breakpoint} {
            > * {
                padding-bottom: $grid-gutter-medium;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .row--small-gutter-#{$breakpoint} {
            > * {
                padding-bottom: $grid-gutter-small;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .row--no-gutter-#{$breakpoint} {
            > * {
                padding-bottom: 0;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
}
