.intro {
  $self: &; // #{ $self }
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  position: relative;

  &__background{
    background-color: $color-pink;
    background-position: 50% 50%;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    right: $grid-gutter;
    bottom: 0;
    left: $grid-gutter;

    &.ios {
      background-attachment: scroll;
    }

  }
  &__image{
    display: none;
  }
  &__text{
    @include z-index(body);
    display: flex;
    .container{
      @include container;
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }
  &__title{
    @include h1;
    display: block;
    line-height: 1;
  }
  &__title-text{
    @include highlight(transparent);
  }
  &__rich-text{
    padding: $grid-gutter;
  }


  &--behind-header{
    margin-top: -$header-height-large;
    min-height: 100vh;

    &.responsive-image {
        margin-top: -100px;
        min-height: auto;

        @media (min-width: 840px) {
            margin-top: -160px;
        }
    }

    #{$self}__background{
      position: static;
      width: 100vw;
      height: 100vh;
        &.responsive_image {
            background-position: top center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    #{$self}__text{
      width: 100vw;
    }
  }

  &--background-ebb{
    background-color: $color-ebb;
  }
  &.ios {
    .scroll-down-intro {
        bottom: 50px;
    }
  }

    #intro-responsive-image {
        display: none;
    }
}
