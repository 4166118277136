.public-update{
  &.modal{
    background-color: $color-peach;
    overflow: initial;
    position: absolute;
    .modal{
      &__header{
        padding: 0.3rem 0 0.1rem;
      }
      &__logo{
        width: 400px;
        padding: 10px 0;
        text-align: center;
        .logo-text {
          @include font-family(noe-display);
          @include font-weight(semibold);
          font-size: 42px;
          line-height: 0.85;
          color: #000;
        }
      }
      &__content{
        color: $color-black;
        margin-bottom: 4rem;
      }
    }
  }

  .icon--close{
    .modal__close{
      position: static;
    }
  }
}
